import styled from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";

export const OptionSelectContainer = styled.li<{selected:boolean, value: any, title: string | number}>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.title.length > 1 ? '0px' : '0px'};
    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 96px;
    height: 36px;
    width:  ${props => props.title.length > 1 ? 'auto' : '36px'};
    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    background: ${props => props.selected ? PRIMARY_COLORS.MEDIUM : 'inherit'};
    color:  ${props => props.selected ? NEUTRAL_COLORS.WHITE : 'inherit'};
    `;

export const Label = styled.label`
    padding:  4px 16px;
    border-radius: 50px;
    background: transparent;
    white-space: nowrap;
`;

export const Input = styled.input`
display: none;
`;
