import { CardNotice } from '../../../../components/CardNotice';
import { CommentsUsers } from '../../../../components/CommentsUsers';
import { ImageCard } from '../../../../components/ImageCard';
import { ImageCarousel } from '../../../../components/ImageCarousel';
import { NavBar } from '../../../../components/NavBar';
import { Section } from '../../../../components/Section';
import { Slider } from '../../../../components/Slider';
import { useSpaProvider } from '../../../../hooks/spaRouterContext';
import { NavContainer, Text } from '../HomeHeader/styles';
import { Container } from './styles';
import {oportunitys, oportunitysWithLink, posts, sectionsIncorporators, sectionsProprietary, unitsYogha, unitsYoghaWithLink} from './textPrototype'

export const HomeBody = () => {



  const notices = [
    {
      title: 'Moradia Flexível',
      description: 'Uma forma de morar descomplicada. Encontre o apê que é a sua cara e fique o tempo que quiser. ',
      icon: require('../../../../assets/svg/MapPin.svg').default
    },
    {
      title: 'Para proprietários',
      description: 'Obtenha a máxima rentabilidade com o seu imóvel sem sair de casa com o Yogha Gestão.',
      icon:  require('../../../../assets/svg/UsersThree.svg').default
    },
    {
      title: 'Para incorporadoras',
      description: 'Transforme o seu empreendimento em um prédio inteligente com o ecossistema Yogha',
      icon:  require('../../../../assets/svg/Briefcase.svg').default
    },
  ]



  const images= [
    {
    icon: require('../../../../assets/images/InicialModalNews1.png')
  },
    {
    icon: require('../../../../assets/images/InicialModalNews2.png')
  },
    {
    icon: require('../../../../assets/images/InicialModalNews3.png')
  },
    {
    icon: require('../../../../assets/images/InicialModalNews1.png')
  },
]

  const comments = [
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lobortis et, sed a, enim ut viverra. Facilisis nunc nec bibendum arcu condimentum.',
        user: {
            name: 'Isabela Carvalho',
            reference: 'Moradora, pelo airbnb',
            icon: require('../../../../assets/images/user.png')
        },
    },
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lobortis et, sed a, enim ut viverra. Facilisis nunc nec bibendum arcu condimentum.',
        user: {
            name: 'Beatriz',
            reference: 'Moradora, pelo airbnb',
            icon: require('../../../../assets/images/user.png')
        },
    },
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lobortis et, sed a, enim ut viverra. Facilisis nunc nec bibendum arcu condimentum.',
        user: {
            name: 'Marisa',
            reference: 'Moradora, pelo airbnb',
            icon: require('../../../../assets/images/user.png')
        },
    },
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lobortis et, sed a, enim ut viverra. Facilisis nunc nec bibendum arcu condimentum.',
        user: {
            name: 'Laura',
            reference: 'Moradora, pelo airbnb',
            icon: require('../../../../assets/images/user.png')
        },
    },
    {
        comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lobortis et, sed a, enim ut viverra. Facilisis nunc nec bibendum arcu condimentum.',
        user: {
            name: 'Jennifer',
            reference: 'Moradora, pelo airbnb',
            icon: require('../../../../assets/images/user.png')
        },
    },
]

  const { pageChosen } = useSpaProvider();

  switch (pageChosen) {
    case 'search':
      return(
        <Container>

        </Container>
      )

    default:
      return(
        <Container>
          <CardNotice notices={notices} imageNotice={images} />
          <ImageCarousel titleCarousel='Oportunidades em destaque' images={oportunitysWithLink}>
              {oportunitysWithLink.map((oportunity:any) => (
        <ImageCard
{...oportunity}
        />
      ))}
            </ImageCarousel>
          <Section {...sectionsProprietary} />
          <div style={{marginTop:20}}>
          <ImageCarousel titleCarousel='Conheça as unidades Yogha' images={unitsYoghaWithLink}>
              {unitsYoghaWithLink.map((unit:any) => (
        <ImageCard
        key={unit.title}
{...unit}
        />
      ))}
            </ImageCarousel>
            </div>
      <Section {...sectionsIncorporators} />
      <div style={{marginTop:20}}>
      <Slider slides={comments}>
      {comments.map((comment) => {
        return (
          <CommentsUsers
            key={comment.user.name}
            comment={comment.comment}
            user={comment.user.name}
            reference={comment.user.reference}
            icon={comment.user.icon}
          />
        );
      })}
      </Slider>
      </div>
      <div style={{marginBottom:10, marginTop:80}}>
      <ImageCarousel titleCarousel='Blog da Yogha' images={posts}>
      {posts.map((post:any) => (
        <ImageCard
        key={post.title}
{...post}
        />
      ))}
      </ImageCarousel>
      </div>
        </Container>
      )
  }
};
