import { InputNumber } from "antd";
import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../../../constants/styleConstants";
import { Button } from "../../../../components/Button";

export const Container = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 670px;
    height: calc(100% - 135px);
    background-color: ${NEUTRAL_COLORS.WHITE};
    border-radius: 10px;
    padding: 0px 32px;
    margin-top: calc(0% + 110px);
/*         @media (max-width: 1360px) {
    height: 496px;
    }
        @media (max-width: 1280px) {
    height: 466px;
    } */
`;

export const TitleFilter = styled.div`
display: flex;
align-items: center;
width: 100%;
height:56px;
justify-content: space-between;
padding: 0px 24px;
color: ${NEUTRAL_COLORS.HIGH_DARK};
font-weight:${FONT_WEIGTH.HUGE};
font-size: ${FONT_SIZE.SMALL};
button{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
border-bottom: 1px solid ${NEUTRAL_COLORS.GRAY};
`;

export const ValueRow = styled.div`
    display: flex;
    height: 48px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
    margin-bottom: 32px;
    `;

export const ValueText = styled.p`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    color: ${NEUTRAL_COLORS.DARK_GRAY};
`;


export const SubTitleFilter = styled.p`
color: ${NEUTRAL_COLORS.HIGH_DARK};
font-weight:${FONT_WEIGTH.HUGE};
align-self: flex-start;
font-size: ${FONT_SIZE.TINY};
margin-top: 8px;
`;



export const ModalContent = styled.div`
    display: inherit;
    flex-direction: inherit;
    height: 100%;
    width: 100%;
    overflow:auto;
    padding: 0px 22px;
    .ant-slider .ant-slider-handle::after{
        box-shadow: 0 0 0 2px ${NEUTRAL_COLORS.DARK_GRAY};
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${PRIMARY_COLORS.LIGHT};
    }
`;


export const RowModal = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    align-items: center;
    padding: 25px 0px;
    justify-content: space-between;
    border-bottom: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    &:nth-child(3){
        margin-top: 32px;
        border-top: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    }
    &:nth-child(6){
        border-bottom: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    }
`;

export const Separator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 99%;
    height: 1px;
    margin: 32px 0px;
    background: purple;
    border: 0.5px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    `;

export const LabelInput = styled.label`
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.MEDIUM};
`;

export const ContainerInput = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    outline-color: ${PRIMARY_COLORS.MEDIUM};
    border-radius: 8px;
    font-weight: ${FONT_WEIGTH.LARGE};
    line-height: 1.4;
    label{
        font-size: ${FONT_SIZE.TINY};
        padding-left:8px;
    }
    `;

export const InputNumberValues = styled.input`
    font-weight: ${FONT_WEIGTH.LARGE};
    border: none;
    outline: none;
    border-radius: 8px;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
    -moz-appearance: textfield;
    padding: 8px 8px;
    font-weight: ${FONT_WEIGTH.LARGE};
`;

export const RowValue = styled.div`
    display: flex;
    flex-direction: column;
    height: 70px;
    width: 302px;
    row-gap: 6px;
`;

export const RowOption = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    justify-content: center;
    gap: 16px;
    `;

export const FilterRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;

export const FilterColumn = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 50%;
    &::after{
        content: '';
        width: 1px;
        height: 100%;
        background-color: ${NEUTRAL_COLORS.DARK_GRAY};
}

`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 77px;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 0px;
    gap:32px;
    `;

export const ButtonFooter = styled.button`
    color: ${NEUTRAL_COLORS.WHITE};
    background-color: ${PRIMARY_COLORS.MEDIUM};
    height: 44px;
    width: 128px;
    cursor: pointer;
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.HUGE};
    border-radius: 10px;
    border: 1px solid ${PRIMARY_COLORS.MEDIUM};
    justify-content: center;
    display: flex;
    align-items: center;
    &:hover {
    background-color: ${PRIMARY_COLORS.DARK};
    }
    gap: 10px;
`;
export const ButtonClean = styled(ButtonFooter)`
    background: transparent;
    border: none;
    color: ${PRIMARY_COLORS.MEDIUM};
 &:hover {
    background-color: transparent;
    }
`;
