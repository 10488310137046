
import { Routes, Route } from 'react-router-dom'
import { Home } from '../pages/Home'
import { Limpeza } from '../pages/Limpeza';
import { Results } from '../pages/Results';
import { Login } from '../pages/Login';

export const Routers = () => {
    return (
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/resultados" element={<Results />} />
                <Route path="/login" element={<Home/>} />
                <Route path="/limpeza" element={<Limpeza />} />
            </Routes>
    );
}