import { createContext, useContext, useState } from 'react';

type AnyContextType = {
  anyValue: string;
  searchValue: string;
  useManager:string;
  setAnyValue: (newState: string) => void;
  setUseManager: (newState: string) => void;
  setSearchValue: (newState: string) => void;
};

const AnyContext = createContext<AnyContextType>({
  anyValue:'',
  setAnyValue: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  searchValue:'',
  setSearchValue: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  useManager:'',
  setUseManager: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
});

const AnyProvider = ({ children }: any) => {
  const [anyValue, setAnyValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [useManager, setUseManager] = useState('');

  return (
    <AnyContext.Provider
      value={{
        anyValue,
        setAnyValue,
        searchValue,
        setSearchValue,
        useManager,
        setUseManager

      }}
    >
      {children}
    </AnyContext.Provider>
  );
};

const useAnyProvider = () => {
  const context = useContext(AnyContext);
  return context;
};

export { useAnyProvider, AnyProvider};

// AnyProvider goes around the components
// import { useAnyProvider } from '/Context'
// const anyValue, setAnyValue = useAnyProvider();
