import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";


export const Container = styled.div`
background: ${NEUTRAL_COLORS.WHITE};
  display: flex;
  width: 302px;
  align-items: center;
  border-radius: 18px;
  border: 0.8px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
  position: fixed;
  z-index: 1040;
  top:9.4%;
  right: 0.5%;
  box-shadow: 0px 3px 25px 4px rgba(0, 0, 0, 0.15);
  `;

export const Modal = styled.div<{user:string}>`
margin: 1rem;
height: ${props => props.user ? '607px' : '556px' };
width: 302px;
display: flex;
flex-direction: column;
align-content: space-between;
row-gap: 15px;
h1{
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.LARGE};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    line-height: 1.4;
}
`
export const TynyText = styled.h5`
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.LITTLE};
    color: ${NEUTRAL_COLORS.GRAY};
    line-height: 1.4;
    margin-bottom: 0.5rem;
`

export const ModalContent = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
row-gap: 5px;
overflow: auto;

`;

export const User = styled.div`
display: flex;
gap:8px;
img{
    height: 40px;
    width: 40px;
    background-color: '#F2F2F2';
    border-radius: 50px;
}
margin-bottom: 24px;
 `;


export const ModalNavLink = styled.div<{arrow?:boolean}>`
width: 250px;
display: inline-flex;
align-items: center;
gap: 10px;
justify-content: ${props => props.arrow ? 'space-between' :'' };
p{
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.HUGE};
    color: ${NEUTRAL_COLORS.DARK};
    white-space: nowrap;
}
`
export const LinkWitArrow = styled.div`
display:inline-flex;
gap:10px;`

export const SectionModal = styled.div`
display:flex;
flex-direction:column;
row-gap: 20px;
margin: 8px 0px;
border-bottom: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
padding-bottom: 40px;
&:last-child{
    border-bottom: none;
}
`