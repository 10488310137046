import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap,ZoomControl} from 'react-leaflet'
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import * as S from './styles';


const iconBuilding = require('../../assets/svg/building.svg').default;

export function Location() {
  const [selectedLum, setSelectedLum] = useState(true)
  const [selectedId, setSelectedId] = useState('')
  const [center, setCenter] = useState<L.LatLngTuple>([-25.44538578640348, -49.283599230713705])
  const [markersData, setMarkersData] = useState<any>([])
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [totalUnits, setTotalUnits] = useState(0)

 let units = 0;

  const arr = [
    {
      "id_unit": 1,
      "name_unit": "Helbor Stay Babel",
      "latitude": -25.44538578640348,
      "longitude": -49.283599230713705,
      "units":2
    },
    {
      "id_unit": 2,
      "name_unit": "7th Avenue Oxford Residence",
      "latitude": -25.436098296065875,
      "longitude": -49.26219807304117,
      "units":3
    },
    {
      "id_unit": 3,
      "name_unit": "All You Nees",
      "latitude": -25.428988198518734,
      "longitude": -49.26917459850827,
      "units":1
    },
    {
      "id_unit": 4,
      "name_unit": "Av. Silva Jardim, 2424 - HSB007",
      "latitude": -25.445259841197963,
      "longitude": -49.28360995955006,
      "units":7
    },
  ]

  useEffect(() => {
      setMarkersData(arr)
  }, []);

  function ChangeMapView({ coords }:any) {
    const map = useMap();
    map.setView(coords, 14);
    return null;
  }

  const LeafIconRed = L.Icon.extend({
    options: {}
  });

  const LeafIconBlue = L.Icon.extend({
    options: {}
  });

  const icon = require('../../assets/svg/closedFilter.svg').default;

  const mapNodeIcon = L.divIcon({
    iconRetinaUrl: `<div">
    <p> ${units} </p>
    </div>`,
    iconAnchor: [10, 10],
    popupAnchor: [0, -19],
    iconSize: [25, 25],
    });

  const handleLum = (item:any) => {
    if (item) {
      setCenter([item.latitude, item.longitude])
      setSelectedId(item.id_luminaria)
      setLatitude(item.latitude)
      setLongitude(item.longitude)
      setSelectedLum(selectedLum ? !selectedLum : selectedLum)
    }
  }

  const handleLumBack = () => {
    setSelectedLum(!selectedLum);
  }


  return (
    <div>
      <S.CustomMapContainer  zoomControl={false} center={center}  scrollWheelZoom={false}>
      <S.CustomZoomControl position="topright" />
        <TileLayer
          url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          subdomains={['mt0','mt1','mt2','mt3']}
        />
        {markersData.map((item:any) =>  (
          <Marker key={item.latitude} eventHandlers={{
            click: (e) => {
              handleLum(item)
            },
          }} position={[item.latitude, item.longitude]}
             icon={L.divIcon({
              html: `<div>
              <p> ${item.units} </p>
              </div>`,
              className: 'iconMap',
              iconAnchor: [10, 10],
              popupAnchor: [5, -19],
              iconSize: [25, 25],
              })
             }
          >
            <S.CustomPopup>
                <span>
              <img src={iconBuilding} alt="" />
              {item.name_unit}
               </span>
                <p>
                {item.latitude}
                </p>
                 <text>
                 {item.units} unidades
                </text>
            </S.CustomPopup>

          </Marker>
        ))}
        <ChangeMapView coords={center} />

      </S.CustomMapContainer>

    </div>


  );
}



/* import {
  MapContainer,
  TileLayer,
  useMap,
  Marker, Popup, ZoomControl
} from "react-leaflet";
import L from 'leaflet';

import { Container } from './styles';

const marker = require('../../assets/images/logo.png')

const Icon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  popupAnchor: [-0, -0],
  iconSize: [100, 50],
});


export const Map = () => {

  return (
    <Container>
      <MapContainer minZoom={8} maxZoom={15} center={[-25.436776472862025, -49.28225510410051]} zoom={13} scrollWheelZoom={false}
      >
        <TileLayer
         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="topright" />
        <Marker position={[-25.436776472862025, -49.28225510410051]} icon={Icon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </Container>
  );
};
 */