import moment from 'moment';
import { useState, useEffect } from 'react';
import { Button } from '../../components/Button';
import { Dropdown, SelectOption, SelectPropsWithFilterAndMap } from '../../components/Dropdown';
import { Input } from '../../components/Input';
import { priceFormatter } from '../../utils/formatter';
import * as S from './styles'
import fetchAxios from '../../services/axios';
import { useAnyProvider } from '../../hooks/Context';
import {useNavigate} from 'react-router-dom'

const logo = require('../../assets/images/logo.png');

interface SelectFunctionaryProps {
    funcionario: string;
    setFuncionario: (newState: string) => void;
}



export const Limpeza = () => {
    const navigate = useNavigate()

    const { useManager } = useAnyProvider();
    const encarregadoName = useManager
    const [encarregado, setEncarregado] = useState<string>(encarregadoName)


    const [form, setForm] = useState({
        Pia: false,
        VasoSanitario: false,
        Blidex: false,
        Chuveiro: false,
        Espelho: false,
        LavaLouca: false,
        SecarEGuardarLouca: false,
        VerificarELimpaGeladeira: false,
        Microondas: false,
        Eletrodomesticos: false,
        PanelasCoposPratosETalheres: false,
        Lixeira: false,
        PapeisHigienicos2: false,
        LimpezaDosArmarios: false,
        LimpezaDeMesaECadeiras: false,
        LimpezaDoChao: false,
        LustraMoveis: false,
        OrganizarCobertorEArmarios: false,
        EnveloparCamaFormatoPadrao: false,
        OrganizarToalhas: false,
        VerificarRodapesEJanelas: false,
        LimpezaDoSofa: false,
    })

    const [valuePayment, setValuePayment] = useState<number>(0);

    const [date, setDate] = useState<Date | string>('');
    const [dayOffChecked, setDayOffChecked] = useState(false);
    const [dayOff, setDayOff] = useState<SelectOption | undefined>(undefined);
    const [typeService, setTypeService] = useState<boolean | undefined>(undefined);

    const [edificio, setEdificio] = useState<string>('');
    const [unidade, setUnidade] = useState<string>('');
    const [observation, setObservation] = useState<string>('');

    const [limpezaType, setLimpezaType] = useState<string>('');
    const [cleanSelectedOption, setCleanSelectedOption] = useState<boolean>();
    const [limpezaData, setLimpezaData] = useState([]);


    const [nomeFuncionario, setNomeFuncionario] = useState<SelectPropsWithFilterAndMap | any>([]);
    const [funcionariosDados, setFuncionariosDados] = useState([]);
    const [funcionario, setFuncionario] = useState<SelectFunctionaryProps | string>('');
    const [funcionarioEmail, setFuncionarioEmail] = useState('');

    const isLoginIn = !!useManager;

useEffect(() => {
    searchFuncionary()
    }, [funcionario])

    async function handleSubmit() {
        try {
            const res = await fetchAxios.put('/salvar', {
                edificio: edificio,
                unidade: unidade,
                tipo: limpezaType,
                feriado: dayOffChecked,
                prestador: funcionario,
                gestor: useManager,
                data: date,
                observacoes: observation,
                pia: form.Pia,
                vaso_sanitario: form.VasoSanitario,
                blindex: form.Blidex,
                chuveiro: form.Chuveiro,
                espelhos: form.Espelho,
                lava_loucas: form.LavaLouca,
                secar_guardar_loucas: form.SecarEGuardarLouca,
                limpar_geladeira: form.VerificarELimpaGeladeira,
                microondas: form.Microondas,
                eletrodomesticos: form.Eletrodomesticos,
                itens_cozinha: form.PanelasCoposPratosETalheres,
                lixeira: form.Lixeira,
                papel_higienico: form.PapeisHigienicos2,
                armarios: form.LimpezaDosArmarios,
                mesa_cadeira: form.LimpezaDeMesaECadeiras,
                chao: form.LimpezaDoChao,
                lustrar_moveis: form.LustraMoveis,
                cobertor_armario: form.OrganizarCobertorEArmarios,
                envelopar_cama: form.EnveloparCamaFormatoPadrao,
                toalhas: form.OrganizarToalhas,
                rodapes_janelas: form.VerificarRodapesEJanelas,
                sofa: form.LimpezaDoSofa,

                valor: valuePayment,
                email: funcionarioEmail,
            })
        } catch (error) {
            alert('Usuário ou senha incorretos')
        }
        alert('Enviado com sucesso')
        handleCheckReset()
        setEdificio('')
        setUnidade('')
        setLimpezaType('')
        setDayOffChecked(false)
        setFuncionario('')
        setDate('')
        setObservation('')
        setFuncionarioEmail('')
        setLimpezaType('')
        setCleanSelectedOption(!cleanSelectedOption)
    }

    const handleCheckReset = () => {
        setForm({
            Pia: false,
            VasoSanitario: false,
            Blidex: false,
            Chuveiro: false,
            Espelho: false,
            LavaLouca: false,
            SecarEGuardarLouca: false,
            VerificarELimpaGeladeira: false,
            Microondas: false,
            Eletrodomesticos: false,
            PanelasCoposPratosETalheres: false,
            Lixeira: false,
            PapeisHigienicos2: false,
            LimpezaDosArmarios: false,
            LimpezaDeMesaECadeiras: false,
            LimpezaDoChao: false,
            LustraMoveis: false,
            OrganizarCobertorEArmarios: false,
            EnveloparCamaFormatoPadrao: false,
            OrganizarToalhas: false,
            VerificarRodapesEJanelas: false,
            LimpezaDoSofa: false,
        })
    }

    async function searchFuncionary() {
        const { data } = await fetchAxios.get('limpeza',
        )
        setLimpezaData(data)
        setNomeFuncionario(data.results)
        console.log('limpeza', data.results)
        const names = data.results
        setFuncionariosDados(names?.result?.map((item: any) => {
            return { label: item.nome, value: item.email }
        }))

        //@ts-ignore
        const emailFilter = funcionariosDados.filter((item: any) => {
            return item.label === funcionario
        })

    //@ts-ignore
    setFuncionarioEmail(emailFilter[0].value)
}

    const values = [{
        label: 'Normal', value: 25
    },
    {
        label: 'Prioridade', value: 30
    }
    ]
    const actualDay = moment().format('DD/MM/YYYY');


    const dayValue = () => {
        if (limpezaType === 'Normal') {
            setValuePayment(25)
        } else if (limpezaType === 'Prioridade') {
            setValuePayment(30)
        } else {
            return 0
        }
    }



    const handleDayOff = () => {
        setDayOffChecked(!dayOffChecked);
        if (!dayOffChecked)
            setValuePayment((prevState) => {
                if (prevState) {
                    return prevState + 5;
                } else {
                    return prevState;
                }
            });
        if (dayOffChecked) {
            setValuePayment((prevState) => {
                if (prevState) {
                    return prevState - 5;
                } else {
                    return prevState;
                }
            });
        }
    };

    const handleInputChange = (event: any) => {
        const { name } = event.target;

        setForm((prevState: any) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const handleLimpezaType = (event: any) => {
        setDayOffChecked(false)
        setLimpezaType(event.target.value);
        if (event.target.value === 'Normal') {
            setValuePayment(25)
        } else if (event.target.value === 'Prioridade') {
            setValuePayment(30)
        } else {
            return 0
        }
    }

    const handleBackLogin = () => {
        navigate('/')
    }


    return (<>
        {isLoginIn ? <S.Container>
            <S.ContentContainer>
                <S.Header>
                    <img src={logo} alt="" />
                </S.Header>
                <div>
                    <S.Title marginBottom={16} marginTop={32}>Checklist - Limpeza</S.Title>
                    <form>
                        <S.Content>
                            <S.Checkbox>
                                <label htmlFor="10">Pia</label>
                                <input id='10' name='Pia' checked={form.Pia} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="11">Vaso Sanitário</label>
                                <input id='11' name='VasoSanitario' checked={form.VasoSanitario} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="12">Blindex</label>
                                <input id='12' name='Blidex' checked={form.Blidex} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="13">Chuveiro</label>
                                <input id='13' name='Chuveiro' checked={form.Chuveiro} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="14">Espelhos</label>
                                <input id='14' name='Espelho' checked={form.Espelho} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="15">Lavar Louça</label>
                                <input id='15' name='LavaLouca' checked={form.LavaLouca} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="16">Secar e Guardar Louça</label>
                                <input id='16' name='SecarEGuardarLouca' checked={form.SecarEGuardarLouca} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="17">Verificar e Limpar Geladeira</label>
                                <input id='17' name='VerificarELimpaGeladeira' checked={form.VerificarELimpaGeladeira} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="18">Microondas</label>
                                <input id='18' name='Microondas' checked={form.Microondas} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="19">Eletrodomésticos</label>
                                <input id='19' name='Eletrodomesticos' checked={form.Eletrodomesticos} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="20">Panelas,Copos,Pratos e Talheres</label>
                                <input id='20' name='PanelasCoposPratosETalheres' checked={form.PanelasCoposPratosETalheres} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="21">Lixeira</label>
                                <input id='21' name='Lixeira' checked={form.Lixeira} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="22">2 Papeis Higiênicos</label>
                                <input id='22' name='PapeisHigienicos2' checked={form.PapeisHigienicos2} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="23">Limpeza dos Armários</label>
                                <input id='23' name='LimpezaDosArmarios' checked={form.LimpezaDosArmarios} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="24">Limpeza de Mesas e Cadeiras</label>
                                <input id='24' name='LimpezaDeMesaECadeiras' checked={form.LimpezaDeMesaECadeiras} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="25">Limpeza do Chão</label>
                                <input id='25' name='LimpezaDoChao' checked={form.LimpezaDoChao} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="26">Lustra Móveis</label>
                                <input id='26' name='LustraMoveis' checked={form.LustraMoveis} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="27">Organizar Cobertor e Armários </label>
                                <input id='27' name='OrganizarCobertorEArmarios' checked={form.OrganizarCobertorEArmarios} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="28">Envelopar a Cama Formato Padrão</label>
                                <input id='28' name='EnveloparCamaFormatoPadrao' checked={form.EnveloparCamaFormatoPadrao} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="29">Organizar Toalhas</label>
                                <input id='29' name='OrganizarToalhas' checked={form.OrganizarToalhas} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="30">Verificar Rodapés e Janelas</label>
                                <input id='30' name='VerificarRodapesEJanelas' checked={form.VerificarRodapesEJanelas} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                            <S.Checkbox>
                                <label htmlFor="31">Limpeza do Sofá</label>
                                <input id='31' name='LimpezaDoSofa' checked={form.LimpezaDoSofa} type="checkbox" onChange={handleInputChange} />
                            </S.Checkbox>
                        </S.Content>
                    </form>
                </div>
                <div>
                    <S.Title marginBottom={32} marginTop={20} style={{ marginTop: '60px' }} >Dados de Limpeza</S.Title>
                    <S.InputContainers>
                        <Input htmlFor='building' onChange={(e) => setEdificio(e.currentTarget.value)} placeholder='' title='Edificío:' value={edificio} type='text' />
                        <Input htmlFor='unit' onChange={(e) => setUnidade(e.currentTarget.value)} placeholder='' title='Unidade:' value={unidade} type='text' />
                        <label style={{ marginBottom: -8, marginTop: -8 }} htmlFor=""> Tipo: </label>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: "100%", marginBottom: 8, }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <input type='radio' radioGroup='typePayment' value="Normal" checked={limpezaType === "Normal"} onChange={handleLimpezaType} />
                                <label style={{ marginBottom: -8, marginTop: -8 }} htmlFor=""> Normal </label>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <input type='radio' radioGroup='typePayment' checked={limpezaType === "Prioridade"} value="Prioridade" onChange={handleLimpezaType} />
                                <label style={{ marginBottom: -8, marginTop: -8 }} htmlFor=""> Prioridade</label>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 0, marginTop: -16 }}>
                            <S.Checkbox>
                                <label htmlFor="27">Domingo/Feriado </label>
                                <input id='27' type="checkbox" checked={dayOffChecked} onClick={handleDayOff} />
                            </S.Checkbox>
                        </div>
                        <Input readOnly htmlFor='conference' onChange={() => { }} placeholder='' title='Conferência realizada por:' value={useManager} type='text' />

                        <S.Label htmlFor="27">Funcionário</S.Label>
                        <Dropdown widthDrop={286} type='input' options={funcionariosDados} search={funcionario} setSearch={setFuncionario} onChange={() => { }} placeholder='Selecione o funcionário' />
                        {/* <Dropdown  widthDrop={302} type='input' onChange={() => { }} placeholder={'Busque por cidade, unidade, bairro...'} search={searchValue} setSearch={setSearchValue} options={cidade} /> */}
                        <Input htmlFor='date' onChange={(e) => setDate(e.currentTarget.value)} type='date' defaultValue={String(new Date())} placeholder='' title='Data:' value={date} />
                        {/*  <Input htmlFor='datePayment' value={datePayment} onChange={(e) => setDatePayment(e.currentTarget.value)} type='date' placeholder='' title='Data do pagamento:' /> */}
                        <Input readOnly htmlFor='doublePrecision' onChange={() => { }} placeholder='' title='Valor:' value={priceFormatter.format(valuePayment)} type='text' />

                        <Input heightInput={'130'} htmlFor='obs' onChange={(e) => setObservation(e.currentTarget.value)} placeholder='' title='Observações:' value={observation} type='text' />

                    </S.InputContainers>
                </div>
                <div style={{ marginTop: '25px', marginBottom: 40 }}>
                    <Button disabled={false} icon={''} onClickButton={handleSubmit} size={'large'} title='Enviar' type='primary' />
                </div>
            </S.ContentContainer>
        </S.Container>
            :
            <S.ContainerLogin>
                <S.ModalLogin>
                    <p>Realize o acesso pra continuar</p>
                    <Button title='Voltar para início' disabled={false} size='large' icon='' onClickButton={handleBackLogin} type='primary' />
                </S.ModalLogin>
            </S.ContainerLogin>}
    </>
    )
}