import { useState } from 'react';
import { Modal } from '../Modal';
import { ModalMenu } from '../ModalMenu';
import { Container, Icon, Photo, PhotoGrid } from './styles';

export const User = () => {

  const menuIcon = require('../../assets/svg/List.svg').default;
  const user = require('../../assets/images/user.png');

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container onClick={()=>setIsOpen(!isOpen)} >
        <Modal isOpen={isOpen} children={<ModalMenu/>}/>
        <Icon src={menuIcon}/>
        <PhotoGrid>
          <Photo src={user} />
        </PhotoGrid>

    </Container>
  );
};
