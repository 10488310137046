import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { NEUTRAL_COLORS } from '../../constants/styleConstants';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

`;

export const ContentCaroussel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 80px;
  padding: 0px 2%;
  flex-wrap: nowrap;
  overflow: hidden;
`;

 export const Carrousel = styled.div`
  display: flex;
  gap: 32px;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  cursor: pointer;
  white-space: nowrap;
  transform: translateX(${(props: { translate: any; }) => props.translate}%);
  margin-left: 1.5%;
  transition: all 0.5s ease-in-out;

  `;

export const Title = styled.text`
  display: flex;
  align-self: flex-start;
  height: 60px;
  margin:0px -25px;
  margin-bottom: -42px;
  color: ${NEUTRAL_COLORS.DARK};
  font-weight: ${FONT_WEIGTH.HUGE};
  font-size: ${FONT_SIZE.MEDIUM};
`;

export const ButtonsContainer = styled.div`
  margin: 0px 3.3%;
  display:flex;
  position:absolute;
  align-items:center;
  justify-content:space-between;
  left:0;
  top:0;
  right:0;
  bottom:32%;
  padding:1rem;
  z-index: 1000;
`;
