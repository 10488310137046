import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../../../constants/fontConstants";

export const Container = styled.div`
  display: flex;
  height:100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 301px;
`;

export const UtilLinks = styled.div`
  display:flex;
  align-items: center;
  background:#f6f4f4;
  justify-content: space-between;
  height: 218px;
  padding:51px 220px 51px 80px;
`;

export const CollumContainer = styled.div`
  display: flex;
  width: 485px;
  align-items: center;
  justify-content: space-around;
  gap: 172px;
`;

export const Collumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  gap: 12px;
  font-size: ${FONT_SIZE.TINY};
  font-weight: ${FONT_WEIGTH.LARGE};
  p{
 &:hover{
  text-decoration: underline;
  cursor: pointer;
 }
  };
`;

export const FooterInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 31px 81px 28px 80px;
  font-size: ${FONT_SIZE.TINY};
  font-weight: ${FONT_WEIGTH.MEDIUM};

`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 50px;
  white-space: nowrap;
`;

export const FooterIcons = styled.div`
  display: flex;
  gap: 31px;
`;