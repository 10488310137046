import styled from "styled-components";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
overflow: hidden;
position: relative;`;

export const Slider = styled.div`
display:flex;
width: 100%;
transform: translateX(${(props: { translate: any; }) => props.translate}%);
transition: all 0.8s ease-in-out;
`;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f6f4f4;
  border-radius: 29px;
  border: 1px solid ${NEUTRAL_COLORS.GRAY} ;
  `

export const ButtonRight = styled(ButtonLeft)`
transform: rotate(180deg);
`;


export const ButtonsContainer = styled.div`
margin: 0px 22%;
display:flex;
position:absolute;
align-items:center;
justify-content:space-between;
left:0;
top:0;
right:0;
bottom:0;
padding:1rem;
`;