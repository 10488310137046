import { useState } from 'react';
import * as S from './styles';
import { ButtonCarousel } from '../ButtonCarousel';

interface ImageCarouselProps {
  images: any,
  children: React.ReactNode,
  titleCarousel: string
};

export const ImageCarousel = ({ images, titleCarousel, children }: ImageCarouselProps) => {

  const [currentSlide, setCurrentSlide] = useState(0)

  const prevSlider = () => {
    setCurrentSlide((currentSlide) => (currentSlide === 0 ? images.length - 4 : currentSlide - 1))
  }

  const nextSlider = () => {
    setCurrentSlide((currentSlide) => (currentSlide === images.length - 4 ? 0 : currentSlide + 1))
  }

  return (
    <S.Container>
      <S.ContentCaroussel>
        <S.Title>{titleCarousel}</S.Title>
        <S.Carrousel translate={(-currentSlide * 297) / images.length}>
          {children}
        </S.Carrousel>
      </S.ContentCaroussel>
      <S.ButtonsContainer>
        <ButtonCarousel direction='left' onClick={prevSlider}/>
        <ButtonCarousel direction='right' onClick={nextSlider}/>
      </S.ButtonsContainer>

    </S.Container>
  );
};
