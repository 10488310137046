import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
display: flex;
width: 100%;
min-height: 66px;
justify-content: center;
align-items: center;
background: transparent;
box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
img{
        width: 130px;
        height: 45px;
    }
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 auto;
  row-gap: 16px;
  margin: 15% 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 320px;
  height: max-content;
  margin-bottom: 16px;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: #3a3a3a;
  margin-top: 24px;
`;