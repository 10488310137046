import { createContext, useContext, useState } from 'react';

type SpaContextType = {
  pageChosen: string;
  visible: boolean;
  expanded: boolean;
  setPageChosen: (newState: string) => void;
  setVisible: (newState: boolean) => void;
  setExpanded: (newState: boolean) => void;
};

const SpaContext = createContext<SpaContextType>({
  pageChosen: 'home',
  visible: true,
  expanded: true,
  setPageChosen: function (): {} {
    throw new Error('Function not implemented.');
  },
  setVisible: function (): {} {
    throw new Error('Function not implemented.');
  },
  setExpanded: function (): {} {
    throw new Error('Function not implemented.');
  }
});

const SpaProvider = ({ children }: any) => {
  const [pageChosen, setPageChosen] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <SpaContext.Provider
      value={{
        visible,
        setVisible,
        pageChosen,
        setPageChosen,
        expanded,
        setExpanded,
      }}
    >
      {children}
    </SpaContext.Provider>
  );
};

const useSpaProvider = () => {
  const context = useContext(SpaContext);
  return context;
};

export { useSpaProvider, SpaProvider };
