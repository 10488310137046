import styled from "styled-components";
import { FONT_SIZE,FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
display: flex;
margin: 0px;
min-width: 100vw;
height: 420px;
background: #f6f4f4;
padding: 64px auto;
justify-content: center;
align-items: center;
flex-grow: 1;
`;

export const Comments = styled.div`
width: 570px;
height: 100%;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
row-gap: 48px;
`;

export const Title= styled.h1`
font-size: ${FONT_SIZE.HUGE};
font-weight: ${FONT_WEIGTH.HUGE};
line-height: 1.4;
padding: 0px 20px;
text-align: center;
`;

export const Comment = styled.p`
font-size: ${FONT_SIZE.MEDIUM};
font-weight: ${FONT_WEIGTH.LARGE};
line-height: 1.4;
height: 84px;
text-align: left;
`;

export const UserContainer = styled.div`
display: flex;
gap: 1rem;
align-self: flex-start;
`

export const UserInfos = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
`;

export const UserName = styled.p`
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.MEDIUM};
`;

export const UserReference = styled(UserName)`
color: ${NEUTRAL_COLORS.DARK_GRAY};`;

