import  * as S from "./styles"

interface IconsProps {
    icon: any;
    height?: string | number;
    width?: string | number;
}

export const Icons = ({icon,height,width}:IconsProps) => {
    return (<S.Images style={{
        height: height,
        width: width,
    }} src={icon}/>);
    };