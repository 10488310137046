import { InputNumber, Slider } from 'antd';
import { useState } from 'react'
import { Button } from '../../../../components/Button';
import { Icons } from '../../../../components/Icons';
import { Input } from "../../../../components/Input";
import { OptionSelect } from "../../../../components/OptionSelect";
import * as S from "./styles";

export const Filter = ({ onClick }: any) => {
    const [bedroom, setBedroom] = useState(0)
    const [beds, setBeds] = useState(0)
    const [bathroom, setBathroom] = useState(0)
    const [petFriendly, setPetFriendly] = useState(0)
    const [valueRange, setValueRange] = useState<any>({ min: 0, max: 1000 })

    const closedFilter = require('../../../../assets/svg/closedFilter.svg').default

    const arr = [{ label: 'Qualquer um', value: 0, indice: 0, key: 10 },
    { label: '1', value: 1, indice: 1, key: 11 },
    { label: '2', value: 2, indice: 2, key: 14 },
    { label: '3', value: 3, indice: 3, key: 12 },
    { label: '4', value: 4, indice: 4, key: 13 },
    ]
    const arrBeds = [{ label: 'Qualquer um', value: 0, indice: 0, key: 15 },
    { label: '1', value: 1, indice: 1, key: 16 },
    { label: '2', value: 2, indice: 2, key: 19 },
    { label: '3', value: 3, indice: 3, key: 17 },
    { label: '4', value: 4, indice: 4, key: 18 },
    ]
    const arrBath = [{ label: 'Qualquer um', value: 0, indice: 0, key: 20 },
    { label: '1', value: 1, indice: 1, key: 21 },
    { label: '2', value: 2, indice: 2, key: 24 },
    { label: '3', value: 3, indice: 3, key: 22 },
    { label: '4', value: 4, indice: 4, key: 23 },
    ]

    const arrpet = [{ label: 'Sim', value: 0, indice: 0 },
    { label: 'Não', value: 1, indice: 1 },
    { label: 'Tanto faz', value: 3, indice: 3 },
    ]

    function getValues(value: any) {
        if (value[0] < value[1]) {
            setValueRange({ min: value[0], max: value[1] });
        }
        if (valueRange.max > value) {
            setValueRange({ min: value })
        } else if (valueRange.min < value) {
            setValueRange({ max: value });
        }

    }

    function handleElementClick(e: React.MouseEvent<HTMLInputElement>) {
        setBedroom((Number(e.currentTarget.value)));
    }

    return (
        <S.Container>
            <S.Modal>
                <S.TitleFilter> <p>Filtros:</p> <button onClick={onClick}> <Icons icon={closedFilter} width={15} height={15} /> </button></S.TitleFilter>
                <S.ModalContent>
                    <S.ValueRow>
                        <S.SubTitleFilter> Valor </S.SubTitleFilter>
                        <S.ValueText>O preço médio por noite são de R$ 98,00</S.ValueText>
                    </S.ValueRow>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 32, marginBottom: 16 }}>
                        <S.RowValue>

                            <S.LabelInput htmlFor="minValue">Mínimo:</S.LabelInput>
                            <S.ContainerInput>
                                <label htmlFor="minValue">R$</label><S.InputNumberValues prefix='R$' defaultValue={50} min={0} max={999} type='number' step={0} id='minValue' value={valueRange.min} onChange={(e: any) => setValueRange({ min: e.currentTarget.value })} />
                            </S.ContainerInput>
                        </S.RowValue>
                        <S.RowValue>
                            <S.LabelInput htmlFor="maxValue">Máximo:</S.LabelInput>
                            <S.ContainerInput>
                                <label htmlFor="minValue">R$</label><S.InputNumberValues defaultValue={1000} min={1} max={1000} type='number' step='0' id='maxValue' prefix='R$' value={valueRange.max} onChange={(e: any) => setValueRange({ max: e.currentTarget.value })} />
                            </S.ContainerInput>
                        </S.RowValue>
                    </div>

                    <Slider value={[valueRange.min, valueRange.max]} range={true} handleStyle={[{ boxShadow: '0px 0px 0px 2px #444444', borderRadius: '10px', content: '2' }]} trackStyle={[{ backgroundColor: '#818181', boxShadow: '0px 0px 0px #444444' }]} min={0} max={1000}
                        onChange={getValues} />
                    <div style={{ borderTop: '1px solid #eeeeee ', width: '100%', marginTop: 32 }}>{''}</div>
                    <S.RowModal>
                        <S.SubTitleFilter> Dormitórios </S.SubTitleFilter>
                        <S.RowOption>
                            {arr.map((item) => {
                                return (
                                    <OptionSelect key={item.label} htmlFor={item.label} selected={item.indice === Number(bedroom) ? true : false} value={item.value} title={item.label} onClick={(e: any) => setBedroom(e.currentTarget.value)} />
                                )
                            })}
                        </S.RowOption>
                    </S.RowModal>
                    <S.RowModal><S.SubTitleFilter> Camas </S.SubTitleFilter>
                        <S.RowOption>
                            {arrBeds.map((item) => {
                                return (
                                    <OptionSelect htmlFor={String(item.key)} selected={item.indice === Number(beds) ? true : false} value={item.value} title={item.label} onClick={(e: any) => setBeds(e.currentTarget.value)} />
                                )
                            })}
                        </S.RowOption></S.RowModal>
                    <S.RowModal><S.SubTitleFilter> Banheiros </S.SubTitleFilter>
                        <S.RowOption>
                            {arrBath.map((item) => {
                                return (
                                    <OptionSelect htmlFor={String(item.key)} selected={item.indice === Number(bathroom) ? true : false} value={item.value} title={item.label} onClick={(e: any) => setBathroom(e.currentTarget.value)} />
                                )
                            })}
                        </S.RowOption></S.RowModal>
                    <S.RowModal><S.SubTitleFilter> Pet friendly </S.SubTitleFilter>
                        <S.RowOption>
                            {arrpet.map((item) => {
                                return (
                                    <OptionSelect htmlFor={item.label} selected={item.indice === Number(petFriendly) ? true : false} value={item.value} title={item.label} onClick={(e: any) => setPetFriendly(e.currentTarget.value)} />
                                )
                            })}
                        </S.RowOption></S.RowModal>
                    <S.SubTitleFilter> Comodidades </S.SubTitleFilter>
                    <S.FilterRow>
                        <S.FilterColumn>
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Wifi" value={""} />
                            <Input htmlFor="gym" type="checkbox" onChange={() => { }} placeholder='' title="Aquecimento a gás" value={""} />
                            <Input htmlFor="coworking" type="checkbox" onChange={() => { }} placeholder='' title="Ar-condicionado" value={""} />
                            <Input htmlFor="washingMachine" type="checkbox" onChange={() => { }} placeholder='' title="Frigobar" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Geladeira" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Cama box" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Cozinha" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Utensílios de cozinha" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Cafeteira" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Sanduicheira" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Smart TV" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="TV a cabo" value={""} />
                        </S.FilterColumn>
                        <S.FilterColumn>
                            <Input htmlFor="pool" type="checkbox" onChange={() => { }} placeholder='' title="Ferro de passar" value={""} />
                            <Input htmlFor="kitchen" type="checkbox" onChange={() => { }} placeholder='' title="Banheira" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Churrasqueira" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Fogão" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Cooktop" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Secador de cabelo" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Liquidificador" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Chaleira" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Ventilador de teto" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Máquina de lavar" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Tanque" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Secadora" value={""} />
                        </S.FilterColumn>
                    </S.FilterRow>
                    <S.Separator></S.Separator>
                    <S.SubTitleFilter> Condomínio </S.SubTitleFilter>
                    <S.FilterRow>
                        <S.FilterColumn>
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Portaria 24 horas" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Academia" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Lavanderia coletiva" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Elevador" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Piscina" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Lavanderia" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Restaurante" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Coworking" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Área verde" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Spa" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Minimarket" value={""} />
                        </S.FilterColumn>
                        <S.FilterColumn>
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Garagem" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Playground" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Quadra esportiva" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Salão de festas" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Salão de jogos" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Brinquedoteca" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Sauna" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Churrasqueira" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Sala de reuniões" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Espaço pet" value={""} />
                        </S.FilterColumn>
                    </S.FilterRow>
                    <S.Separator></S.Separator>
                    <S.SubTitleFilter> Acessibilidade </S.SubTitleFilter>
                    <S.FilterRow>
                        <S.FilterColumn>
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Banheiro adaptado" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Corrimão" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Piso tátil" value={""} />
                        </S.FilterColumn>
                        <S.FilterColumn>
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Portas amplas nos quartos e corredores" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Rampas de acesso" value={""} />
                            <Input htmlFor="wifi" type="checkbox" onChange={() => { }} placeholder='' title="Vaga de garagem acessível" value={""} />
                        </S.FilterColumn>
                    </S.FilterRow>
                </S.ModalContent>
                <div style={{ height: 1, width: '107%', background: '#b9b9b9' }}>{''}</div>
                <S.ButtonRow>
                    <S.ButtonClean> Limpar filtros </S.ButtonClean>                <S.ButtonFooter>Ver resultados</S.ButtonFooter>
                </S.ButtonRow>
            </S.Modal>
        </S.Container>
    );
};