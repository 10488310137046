import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, STATUS_COLORS } from "../../constants/styleConstants";

export const Container = styled.div``;

export const ResultsContainer = styled.div<{ closed: boolean }>`
width: ${props => props.closed ? '0px' : '51.2%'};
height: calc(100%);
display: ${props => props.closed ? 'none' : 'flex'};
position: absolute;
top: 86px;
z-index: 1;
transition: all 0.8s ease-in-out;
transition: display 1.2s ease-in-out;
z-index: 1000;
background: ${NEUTRAL_COLORS.WHITE};
flex-direction: column;
`;

export const ResultsHeader = styled.div`
display: flex;
flex-direction: column;
height: 116px;
width: 100%;
justify-content: space-between;
align-items: flex-start;
row-gap: 16px;
border-bottom:1px solid ${STATUS_COLORS.DISABLED};
`;

export const ResultRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
`;

export const ContentResult = styled.div`
display: flex;
height: 80px;
width: 413px;
padding: 16px 32px;
`;

export const InfoSearch = styled.div`
display: flex;
flex-direction: column;
row-gap: 20px;
`;

export const ResultsTitle = styled.p`
display: flex;
align-items: center;
gap: 13px;
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.MEDIUM};
color: ${NEUTRAL_COLORS.DARK_GRAY};
`;

export const SearchResult = styled(ResultsTitle)`
color: ${NEUTRAL_COLORS.DARK};
`;

export const Results = styled.p`
display: flex;
align-items: flex-start;
font-weight: ${FONT_WEIGTH.HUGE};
font-size: ${FONT_SIZE.SMALL};
`;

export const ResultsList = styled.div`
display: flex;
overflow: auto;
width: calc(100% - 32px);
padding-top: 16px;
padding-left: 32px;

`;

export const ResultContent = styled.div`
display: flex;
flex-wrap: wrap;
row-gap: 32px;
gap: 32px;
width: fit-content;
height: fit-content;
`;


export const FilterButton = styled.button`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
width: 106px;
height: 40px;
border-radius: 10px;
border: 1px solid ${NEUTRAL_COLORS.GRAY};
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.HUGE};
line-height: 1.4;
background: ${NEUTRAL_COLORS.WHITE};
padding:0px 8px;
margin-top: 32px;
margin-right: 32px;
`;

export const ClosedButton = styled.div<{ closed: boolean }>`
display: flex;
justify-content: center;
align-items: center;
width:max-content;
height: 40px;
background: ${NEUTRAL_COLORS.WHITE};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
position: absolute;
top: 12%;
border-radius: 10px;
left: ${props => props.closed ? '1%' : '52%'};;
z-index: 999;
transition: all 0.8s ease-in-out;
padding: 0px 10px;
`;

