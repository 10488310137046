import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "../../constants/styleConstants";
import { IInput } from "./types";

type InputProps = Omit<IInput, "htmlFor" | "erroText" | "title" | "inputType">

const checked = require("../../assets/svg/Checked.svg").default;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 5px;
    margin-top: -5px;


`;

export const InputLabel = styled.label``;


export const TextArea = styled.textarea`
    width: calc(100% - 16px);
    resize: none;
    padding: 8px;
    line-height: 1.4;
    border-radius: 10px;
    font-size: ${FONT_SIZE.TINY};
    height: 140px;
    ::placeholder {
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    :focus{
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        outline: none;
        border: 1px solid ${PRIMARY_COLORS.MEDIUM};
        ::placeholder {
            color: ${NEUTRAL_COLORS.HIGH_DARK};
        }
    }

    :disabled{
        color: ${STATUS_COLORS.DISABLED};
        border: 1px solid ${STATUS_COLORS.DISABLED};
    }


`

export const Inputs = styled.input<InputProps>`
    border: 1px solid ${props => props.errorMessages === true ? STATUS_COLORS.ERROR : props.disabled ? STATUS_COLORS.DISABLED :  NEUTRAL_COLORS.GRAY};
    line-height: 1.4;
    border-radius: 10px;
    font-size: ${FONT_SIZE.TINY};
    padding-left: 1rem;
    width: 302px;
    height: ${props => props.heightInput !== undefined ? props.heightInput : 52  }px;
    color: ${props => props.disabled  ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK };

    ::placeholder {
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    :focus{
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        outline: none;
        border: 1px solid ${PRIMARY_COLORS.MEDIUM};
        ::placeholder {
            color: ${NEUTRAL_COLORS.HIGH_DARK};
        }
    }

    :disabled{
        color: ${STATUS_COLORS.DISABLED};
        border: 1px solid ${STATUS_COLORS.DISABLED};
    }

    datalist{
        width: 100%;
        option{
            color: ${NEUTRAL_COLORS.HIGH_DARK};
            width: 100%;
            background: red;
        };
    }
`;

export const ErrorText = styled.p<{errorMessages:boolean}>`
    font-size: ${FONT_SIZE.TINY};
    line-height: 1.4;
    color: ${props => props.errorMessages && STATUS_COLORS.ERROR};
`;

export const CheckboxContainer = styled.div`
    display: flex;
    width: auto;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    margin-top: 10px;
    label{
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.MEDIUM};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    input[type="checkbox"]{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        border: 2px solid ${NEUTRAL_COLORS.GRAY};
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            font-family: "Font Awesome 5 Free";
            content: "\f00c";
            font-weight: 700;
            font-size: ${FONT_SIZE.TINY};
            color: ${NEUTRAL_COLORS.WHITE};
            display: none;
        };
        &:hover{
            background: ${NEUTRAL_COLORS.LIGHT_GRAY};
        }
        &:checked{
            background: ${PRIMARY_COLORS.MEDIUM};
            border: 0;
        }
        &:checked::after{
            display: block;
        }
    }`;

    export const CheckboxLabel = styled.label`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM}; ;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    `;
