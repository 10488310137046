import { Container, Image } from './styles';

export const Logo = () => {

  const logo = require('../../assets/images/logo.png');

  return (
    <Container>
        <Image src={logo}/>
    </Container>
  );
};
