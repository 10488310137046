import { Icons } from '../Icons'
import * as S from './styles'

export type CardSeeMoreProps = {
    title: string
    description: string
    icon: string
}

export const CardSeeMore = ({ title, description,icon }: CardSeeMoreProps) => {

    const seeMoreIcon = require('../../assets/svg/arrowCard.svg').default;

    return (
        <S.Container>
        <S.Content>
        <S.Header>
            <Icons icon={icon}/> <S.Title>{title}</S.Title>
        </S.Header>
        <S.Description>{description}</S.Description>
        <S.Link>
             <p>
             Saiba mais
            </p>
            <Icons icon={seeMoreIcon} width={6} height={11} />  </S.Link>
        </S.Content>
    </S.Container>
    )
}



