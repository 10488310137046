import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100svw;
    height: 100svh;
    header > img{
        width: 130px;
        height: 45px;
    }
    input[type="radio"]{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid ${NEUTRAL_COLORS.GRAY};
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            font-family: "Font Awesome 5 Free";
            content: "\f111";
            font-weight: 700;
            align-self: center;
            font-size: ${FONT_SIZE.TINY};
            color: ${NEUTRAL_COLORS.WHITE};
            display: none;
        };
        &:hover{
            background: ${NEUTRAL_COLORS.LIGHT_GRAY};
        }
        &:checked{
            background: ${PRIMARY_COLORS.MEDIUM};
            border: 0;
        }
        &:checked::after{
            display: block;
        }
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    overflow-y: scroll;
`;

export const Header = styled.header`
display: flex;
width: 100%;
min-height: 66px;
justify-content: center;
align-items: center;
background: transparent;
box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
`;

export const Title = styled.h1<{marginTop:number|string,marginBottom:number|string,}>`
    margin-top: ${props => props.marginTop}px;
    margin-bottom:${props => props.marginBottom}px;
    font-size: 22px;
    align-self: center;
    text-align: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 350px;
    align-items: center;
    justify-content: center;
    label{
        font-size: 16px;
        font-weight: 400;

    }
`;

export const Checkbox = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    margin-top: 10px;
    label{
        display: flex;
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.MEDIUM};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        align-items: flex-end;
    }
    input[type="checkbox"]{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        border: 2px solid ${NEUTRAL_COLORS.GRAY};
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            font-family: "Font Awesome 5 Free";
            content: "\f00c";
            font-weight: 700;
            font-size: ${FONT_SIZE.TINY};
            color: ${NEUTRAL_COLORS.WHITE};
            display: none;
        };
        &:hover{
            background: ${NEUTRAL_COLORS.LIGHT_GRAY};
        }
        &:checked{
            background: ${PRIMARY_COLORS.MEDIUM};
            border: 0;
        }
        &:checked::after{
            display: block;
        }
    }
`;


export const Label = styled.label`

    margin-top:-8px;
    margin-bottom: -10px;
`;
export const InputContainers = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    row-gap: 16px;

    input[type="date"]{
    width: calc(100% - 1.5rem);
    padding-right: .5rem ;
        ::-webkit-calendar-picker-indicator{
        font-size: 22px;
        cursor: pointer;
        border-radius: 3px;
    }
}

    `

export const ContainerLogin = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: 100vh;
background: ${NEUTRAL_COLORS.HIGH_DARK}80;
`;

export const ModalLogin = styled.div`
 display: flex;
 flex-direction: inherit;
 width: 70%;
 height: 300px;
 background: ${NEUTRAL_COLORS.WHITE};
 border-radius: 10px;
 align-items: center;
 justify-content: center;
 row-gap: 16px;
 padding: 16px;
 p{
        text-align: justify;
        font-size: ${FONT_SIZE.SMALL};
        font-weight: ${FONT_WEIGTH.LARGE};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        margin-top: 10px;
        margin-bottom: 10px;
 }
`;