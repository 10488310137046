import { useEffect } from 'react';
import * as S from './styles';

export interface ModalProps {
  isOpen?: boolean;
  setIsOpen?: (open: boolean) => void;
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ children, isOpen, setIsOpen }) => {
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget && setIsOpen) {
      setIsOpen(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && setIsOpen) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey]);

  return (
    <>
      {isOpen && (
        <S.Overlay  onClick={handleOverlayClick}>
          <S.Container>
            <S.Content>{children}</S.Content>
          </S.Container>
        </S.Overlay>
      )}
    </>
  );
};
