import {useState} from 'react'
import { Icons } from '../Icons';
import * as S from './styles'

interface SliderProps {
    children: React.ReactNode;
    slides: any;
}

export const Slider = ({children,slides}:SliderProps) => {

const [currentSlide, setCurrentSlide] = useState(0)

const arrowIcon = require('../../assets/svg/arrowComments.svg').default;

const prevSlider = () => {
    setCurrentSlide((currentSlide) => (currentSlide === 0 ? slides.length - 1 : currentSlide - 1))
    }

const nextSlider = () => {
    setCurrentSlide((currentSlide) => (currentSlide ===  slides.length - 1 ? 0 : currentSlide + 1))
    }

    return (
        <S.Container>
            <S.Slider translate={-currentSlide * 100.4}>{children}</S.Slider>
            <S.ButtonsContainer>
                <S.ButtonLeft onClick={prevSlider}><Icons icon={arrowIcon} width={24} height={24}/></S.ButtonLeft>
                <S.ButtonRight onClick={nextSlider}><Icons icon={arrowIcon}  width={24} height={24}/></S.ButtonRight>
            </S.ButtonsContainer>
        </S.Container>
    )
}