import { Button } from '../Button';
import { Icons } from '../Icons';
import { AddressText, Container, GlobalContainer, Image, Infos, LinkContainer, LinkText, PostContainer, ScaleContainer, ScaleImage, SearchContainer, SearchDescription, TextFrom, TextOthers, TextPost, TextPrice, TextTitle } from './styles';
import { ImageCardProps } from './types';


export const ImageCard = ({ ...card }:ImageCardProps) => {

  return (
    <>
      { card.cardType !== 'search' && <GlobalContainer>
        <Container onClick={card.onClickImageCard}>
          {card.cardType === 'imoveis' &&
            <>
              <Image src={card.image} />
              <Infos>
                <TextTitle>{card.title}</TextTitle>
                <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
                  <AddressText>{card.address}</AddressText>
                  {card.location !== null && <AddressText>{card.location}</AddressText>}
                </div>
              </Infos>
              {card.hasFrom && <TextFrom>A partir de:</TextFrom>}
              <TextOthers><TextPrice>{`R$ ${card.price},00 `}</TextPrice>{'noite'}</TextOthers>
              {card.description !== null && <TextOthers>{card.description}</TextOthers>}
            </>}

        </Container>
        {card.cardType === 'posts' &&
          <PostContainer>
            <Image src={card.image} />
            <TextPost>{card.describePost}</TextPost>
          </PostContainer>
        }
        {card.cardType === 'link' &&
          <>
            <LinkContainer sizeCard={card.sizeCard}>
              <Icons icon={card.imageLink} width={80} height={80} />
              <LinkText>{card.linkText}</LinkText>
              <div style={{ marginTop: '2px', marginBottom: '-20px' }}>
                <Button onClickButton={() => { }} title={card.buttonText as string} icon={''} size='small' disabled={false} type='primary' />
              </div>
            </LinkContainer>
          </>}

      </GlobalContainer>}
      {card.cardType === 'search' &&
        <ScaleContainer>
            <SearchContainer>
              <ScaleImage src={card.image} />
              <Infos>
                <TextTitle>{card.title}</TextTitle>
                <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
                  <AddressText>{card.address}</AddressText>
                  {card.location !== null && <AddressText>{card.location}</AddressText>}
                </div>
              </Infos>
              <SearchDescription>{card.description}</SearchDescription>
              <TextOthers><TextPrice>{`R$ ${card.price},00 `}</TextPrice>{'noite'}</TextOthers>
            </SearchContainer>
        </ScaleContainer>}
    </>
  );
};
