import { useContext } from 'react';
import * as S from './styles'
import { UserContext, UserContextType } from '../../hooks/userContext'
import { Icons } from '../Icons';

export const ModalMenu = () => {

    const favIcon = require("../../assets/svg/favIcon.svg").default
    const userIcon = require("../../assets/svg/userIcon.svg").default
    const exitIcon = require("../../assets/svg/exitIcon.svg").default
    const enterIcon = require("../../assets/svg/enterIcon.svg").default
    const confirmReservsIcon = require("../../assets/svg/confirmReservsIcon.svg").default
    const helpCircle = require("../../assets/svg/help-circle.svg").default
    const arrowRight = require("../../assets/svg/arrowRight.svg").default

    const {user} = useContext<UserContextType>(UserContext)

    const image = require('../../assets/images/user.png');

   return (
    <>
    <S.Container>
        <S.Modal user={'Peter'}>
            <S.ModalContent>
            { user !== null ? <S.User>
                <img src={image} alt="" />
                <div>
                  <h1>Olá,</h1>
                  <h1>Sarah</h1>
                </div>
            </S.User>
             :
             <div>
            <S.SectionModal>
                <S.TynyText>Minha conta:</S.TynyText>
                <S.ModalNavLink arrow> <S.LinkWitArrow><Icons icon={enterIcon}  /> <p>Entrar</p></S.LinkWitArrow> <Icons icon={arrowRight} width={6} height={11} /></S.ModalNavLink>
            </S.SectionModal>
            </div> }
                { user !== null && <S.SectionModal>
                    <S.TynyText>Minha conta:</S.TynyText>
                    <S.ModalNavLink> <Icons icon={userIcon} width={20} height={19}/> <p>Informações Pessoais</p></S.ModalNavLink>
                    <S.ModalNavLink> <Icons icon={confirmReservsIcon} width={18} height={19.5}   /> <p>Minhas reservas</p></S.ModalNavLink>
                    <S.ModalNavLink><Icons icon={favIcon} width={21} height={18} /> <p>Favoritos</p></S.ModalNavLink>
                    <S.ModalNavLink><Icons icon={exitIcon} width={18} height={18} /> <p>Sair</p></S.ModalNavLink>
                </S.SectionModal>}
                <S.SectionModal>
                    <S.TynyText>Sou proprietário</S.TynyText>
                    <S.ModalNavLink arrow><p>Área do proprietário</p> <Icons icon={arrowRight} width={6} height={11}/> </S.ModalNavLink>
                </S.SectionModal>
                <S.SectionModal>
                    <S.TynyText>Precisa de ajuda?</S.TynyText>
                    <S.ModalNavLink> <Icons icon={helpCircle} width={19.5} height={19.5} />  <p>Ajuda - Suporte</p></S.ModalNavLink>
                    <S.ModalNavLink> <Icons icon={helpCircle} width={19.5} height={19.5} /> <p>Ajuda - Quero alugar</p></S.ModalNavLink>
                </S.SectionModal>
                <S.SectionModal>
                    <S.TynyText>Espaõ Yogha:</S.TynyText>
                    <S.ModalNavLink><p>Blog da Yogha</p></S.ModalNavLink>
                    <S.ModalNavLink><p>Quem somos</p></S.ModalNavLink>
                </S.SectionModal>
            </S.ModalContent>
        </S.Modal>
    </S.Container>

    </>
)}