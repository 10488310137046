import { Container, Icon } from './styles';

interface ButtonProps {
  title: string
  size: 'large' | 'small',
  type: 'primary' | 'secondary',
  icon: string,
  disabled: boolean,
  onClickButton: any,
  iconHeight?: number | string,
  iconWidth?: number | string,
  iconDirection?: 'left' | 'right',
};

export const Button: React.FC<ButtonProps> = ({title, size, type, disabled, icon, onClickButton, iconWidth, iconHeight,iconDirection}) => {

  return (
    <Container iconDirection={iconDirection} disabled={disabled} size={size} type={type} onClick={ disabled ? ()=>{} : onClickButton}>
      { icon.length > 0 && <Icon src={icon} width={iconWidth} height={iconHeight}/> }
      {title}
    </Container>
  );
};
