import styled, { keyframes } from 'styled-components';
import { NEUTRAL_COLORS } from '../../../../constants/styleConstants';

const growDown = keyframes`
  from {
    height: 84px;
  }
  to {
    height: 585px;
  }
`;

const growUp = keyframes`
  from {
    height: 585px;
  }
  to {
    height: 84px;
  }
`;

const slideRight = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

export const HidedContainer = styled.div<{expanded: boolean}>`
  display: flex;
  animation-duration: 1000ms;
  animation-name: ${props => !props.expanded && slideRight};
  gap: 16px;
`;

export const GlobalContainer = styled.div<{expanded: boolean}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: ${props => props.expanded ? 'center' : 'flex-start'};
  height: ${props => props.expanded ? '86px' : '585px'};
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  align-items: ${props => props.expanded ? 'center' : 'flex-start'};
  animation-duration: 800ms;
  animation-name: ${props => !props.expanded && growDown};
  animation-name: ${props => props.expanded && growUp};
  position: absolute;
  z-index: 1010;
  background-color: ${NEUTRAL_COLORS.WHITE};
`;

export const HeaderContainer = styled.div<{expanded: boolean}>`
  width: 100%;
  background-color: ${NEUTRAL_COLORS.WHITE};
  display: flex;
  padding-top: ${props => props.expanded ? '0px' : '15px'};
`;

export const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0 80px;
`;

export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 80px;
`;

export const NavContainer = styled.div`

`;

export const Text = styled.text`
  display: flex;
  align-items: center;
  height: 60px;
  font-weight: 600;
  font-size: 20px;
  margin-left: 82px;
`;
