import { useState,useEffect } from 'react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import * as S from './styles';
import fetchAxios from '../../services/axios';
import { useAnyProvider } from '../../hooks/Context';
import { useNavigate } from 'react-router-dom';

const logo = require('../../assets/images/logo.png');

/* {[15:17, 03/04/2023] Maxwell: "vivianne.santos@yogha.com.br"
[15:17, 03/04/2023] Maxwell: "10968072704"}  */

export const Login = () => {
  const navigate = useNavigate()

  const { useManager,setUseManager } =  useAnyProvider();
  const [email, setEmail] = useState<string>('')
  const [cpf, setCpf] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [userFilter, setUserFilter] = useState<string>('')
  const [pass, setPass] = useState<boolean>(false)

async function handleLogin() {
  try {
    const res = await fetchAxios.post('/login', {
      email:email, document: password
    })
    console.log(res.data.result[0])
    if (res.data.result[0].name !== '') {
      setUseManager(res.data.result[0].name)
      navigate('/limpeza');
    }
  } catch (error) {
    alert('Usuário ou senha incorretos')
  }
}

  return (
    <S.Container>
      <S.Header>
          <img src={logo} alt="" />
      </S.Header>
      <S.Content>
        <S.TitleContainer>
          <S.Title>Entrar</S.Title>
        </S.TitleContainer>
        <Input htmlFor='email' onChange={e => setEmail(e.currentTarget.value)} placeholder='Email' title='Email' type='text' value={email} />
        <Input htmlFor='password' onChange={e => setPassword(e.currentTarget.value)} placeholder='CPF só numeros' title='Senha' type='password' value={password} />
        <div style={{marginTop:10}}>

        <Button type='primary' disabled={false} icon='' onClickButton={handleLogin} title='Entrar' size='large' />
        </div>

        {/*       <p>Criar nova conta</p>
      <p>Ou continuar com</p>
    <div><button></button> <button></button></div> */}
      </S.Content>
    </S.Container>
  );
};
