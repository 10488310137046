import styled from 'styled-components';
import { STATUS_COLORS, PRIMARY_COLORS, NEUTRAL_COLORS } from '../../constants/styleConstants';
import { FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';

export const Container = styled.div<{disabled: boolean, size: string, type: string, iconDirection?:string}>`
  color: ${props => props.disabled && props.type !== 'primary' ? STATUS_COLORS.DISABLED : props.type === 'primary' ? NEUTRAL_COLORS.WHITE : PRIMARY_COLORS.MEDIUM};
  background-color: ${props => props.disabled ? STATUS_COLORS.DISABLED : props.type === 'secondary' ? NEUTRAL_COLORS.WHITE : PRIMARY_COLORS.MEDIUM};
  height: ${props => props.size === 'large' ? '52px' : props.size === 'small' ? '36px' : '44px'};
  font-size: ${props => props.size === 'large' ? FONT_SIZE.SMALL : FONT_SIZE.TINY};
  width: ${props => props.size === 'large' ? '152px' : '137px'};
  cursor: ${props => props.disabled ? null : 'pointer'};
  font-weight: ${FONT_WEIGTH.HUGE};
  border-radius: 10px;
  border: 1px solid ${props => props.disabled ? STATUS_COLORS.DISABLED : PRIMARY_COLORS.MEDIUM } ;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${props => props.disabled ? STATUS_COLORS.DISABLED : PRIMARY_COLORS.DARK } ;
  }
  gap: 10px;
  flex-direction: ${props => (props.iconDirection === 'left' && 'row') || (props.iconDirection === 'right' && 'row-reverse')};
`;

export const Icon = styled.img`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;