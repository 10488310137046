import styled from 'styled-components';

export const Container = styled.div`
margin: 0;
`;

export const Image = styled.img`
  width: 112px;
  height: 40px;
  cursor: pointer;
`;