export const sectionsProprietary =
    {
      headerText: 'Para proprietários',
      title:'Potencialize a sua rentabilidade com o Yogha Gestão',
      text:'Yogha Gestão garante aos nossos parceiros proprietários uma administração completa e inteligente do seu imóvel, visando a máxima rentabilidade, de forma ágil e transparente.',
      image: require('../../../../assets/images/proprietary.png'),
      buttonText:'Conheça',
    }

export const sectionsIncorporators =
        {
            headerText: 'Para incorporadoras',
            title:'Você tem o projeto e a Yogha, a expertise',
            text:'A Yogha conecta o seu imóvel a serviços, marcas e tecnologias que o ajudarão a maximizar os seus resultados. Conheça as soluções personalizadas para o seu prédio.',
            image: require('../../../../assets/images/incorporators.png'),
            buttonText:'Saiba mais',
          }

export const posts = [
            {describePost:'ESG no mercado imobiliário: sustentabilidade e ações sociais serão tendências em 2023 ',
            image:require('../../../../assets/images/Rectangle2.png'),
          cardType:'posts' },
            {describePost:'Tendências do mercado imobiliário para 2023',
            image:require('../../../../assets/images/Rectangle1.png'),
            cardType:'posts' },
            {describePost:'Moradias flexíveis são tendência para 82% dos jovens brasileiros',
            image:require('../../../../assets/images/Rectangle3.png'),
            cardType:'posts' },
            {describePost:'Rua Riachuelo, 110 - AYN001',
            image:require('../../../../assets/images/Rectangle3.png'),
            cardType:'posts' },
            {describePost:'ESG no mercado imobiliário: sustentabilidade e ações sociais serão tendências em 2023 ',
            image:require('../../../../assets/images/Rectangle2.png'),
            cardType:'posts' },
            {describePost:'Moradias flexíveis são tendência para 82% dos jovens brasileiros',
            image:require('../../../../assets/images/Rectangle3.png'),
            cardType:'posts' },
            {describePost:'Rua Riachuelo, 110 - AYN001',
            image:require('../../../../assets/images/Rectangle3.png'),
            cardType:'posts' },
            {describePost:'ESG no mercado imobiliário: sustentabilidade e ações sociais serão tendências em 2023 ',
            image:require('../../../../assets/images/Rectangle2.png'),
            cardType:'posts' } ,
            {
            imageLink:require('../../../../assets/svg/NewBlogNotices.svg').default,
            cardType:'link',
            buttonText:'Visitar blog',
            linkText:"Quer acompanhar essas e outras matérias?",
            sizeCard:'posts',
           },
          ]

export const citys = [
            {describePost:'Curitiba',
            image:require('../../../../assets/images/citys/Curitiba.png'),
          cardType:'posts' },
            {describePost:'Rio de Janeiro',
            image:require('../../../../assets/images/citys/RioDeJaneiro.png'),
            cardType:'posts' },
            {describePost:'São Paulo',
            image:require('../../../../assets/images/citys/SaoPaulo.png'),
            cardType:'posts' },
            {describePost:'Florianópolis',
            image:require('../../../../assets/images/citys/Florianopolis.png'),
            cardType:'posts' },
            {describePost:'Campinas',
            image:require('../../../../assets/images/citys/Curitiba.png'),
          cardType:'posts' },
            {describePost:'Salvador',
            image:require('../../../../assets/images/citys/RioDeJaneiro.png'),
            cardType:'posts' },
            {describePost:'Campo Grande',
            image:require('../../../../assets/images/citys/SaoPaulo.png'),
            cardType:'posts' },
            {describePost:'Porto Alegre',
            image:require('../../../../assets/images/citys/Florianopolis.png'),
            cardType:'posts' },
            {describePost:'Poços de Caldas',
            image:require('../../../../assets/images/citys/SaoPaulo.png'),
            cardType:'posts' },
            {describePost:'Recife',
            image:require('../../../../assets/images/citys/Florianopolis.png'),
            cardType:'posts' },
          ]

export const oportunitys= [
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('../../../../assets/images/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('../../../../assets/images/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('../../../../assets/images/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('../../../../assets/images/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('../../../../assets/images/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('../../../../assets/images/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('../../../../assets/images/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
            },
          ];

export const oportunitysWithLink= [
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('../../../../assets/images/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('../../../../assets/images/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('../../../../assets/images/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('../../../../assets/images/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('../../../../assets/images/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('../../../../assets/images/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              imageLink:require('../../../../assets/svg/Percent.svg').default,
              cardType:'link',
              buttonText:'Ver ofertas',
              linkText:"Conheça todas as nossas ofertas",
              sizeCard:'imoveis'
             },
          ];

export const unitsYogha= [
            {
              cardType:'imoveis',
              title: 'Helbor Stay Batel ',
              image: require('../../../../assets/images/unitsYogha1.png'),
              location: 'Batel - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: '7th Avenue Oxford Residence',
              image: require('../../../../assets/images/unitsYogha2.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Mercado Municipal  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'All You Need',
              image: require('../../../../assets/images/unitsYogha3.png'),
              location: 'Centro - Curitiba, PR',
              address: 'UFPR  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/unitsYogha4.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Helbor Stay Batel ',
              image: require('../../../../assets/images/unitsYogha1.png'),
              location: 'Batel - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: '7th Avenue Oxford Residence',
              image: require('../../../../assets/images/unitsYogha2.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Mercado Municipal  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'All You Need',
              image: require('../../../../assets/images/unitsYogha3.png'),
              location: 'Centro - Curitiba, PR',
              address: 'UFPR  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/unitsYogha4.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Helbor Stay Batel ',
              image: require('../../../../assets/images/unitsYogha1.png'),
              location: 'Batel - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: '7th Avenue Oxford Residence',
              image: require('../../../../assets/images/unitsYogha2.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Mercado Municipal  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
          ];

export const unitsYoghaWithLink= [
            {
              cardType:'imoveis',
              title: 'Helbor Stay Batel ',
              image: require('../../../../assets/images/unitsYogha1.png'),
              location: 'Batel - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: '7th Avenue Oxford Residence',
              image: require('../../../../assets/images/unitsYogha2.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Mercado Municipal  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'All You Need',
              image: require('../../../../assets/images/unitsYogha3.png'),
              location: 'Centro - Curitiba, PR',
              address: 'UFPR  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/unitsYogha4.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Helbor Stay Batel ',
              image: require('../../../../assets/images/unitsYogha1.png'),
              location: 'Batel - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: '7th Avenue Oxford Residence',
              image: require('../../../../assets/images/unitsYogha2.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Mercado Municipal  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'All You Need',
              image: require('../../../../assets/images/unitsYogha3.png'),
              location: 'Centro - Curitiba, PR',
              address: 'UFPR  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('../../../../assets/images/unitsYogha4.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: 'Helbor Stay Batel ',
              image: require('../../../../assets/images/unitsYogha1.png'),
              location: 'Batel - Curitiba, PR',
              address: 'Praça do Japão 500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
              cardType:'imoveis',
              title: '7th Avenue Oxford Residence',
              image: require('../../../../assets/images/unitsYogha2.png'),
              location: 'Centro - Curitiba, PR',
              address: 'Mercado Municipal  500m',
              price: '250',
              description: '',
              hasFrom: true,
            },
            {
                imageLink:require('../../../../assets/svg/Units.svg').default,
                cardType:'link',
                buttonText:'Ver ofertas',
                linkText:"Conheça todas as unidades",
                sizeCard:'imoveis'
                           },
          ];