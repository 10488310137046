import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  overflow-x: scroll;
`;

export const BlurEffects = styled.div<{blur:boolean}>`
${props => props.blur === false && `
  filter: blur(8px);
`};
height: 20%;
`;
