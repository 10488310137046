import { Button } from '../Button';
import { Icons } from '../Icons';
import * as S from './styles';

interface SectionProps {
  headerText: string;
  title: string;
  text: string;
  buttonText: string;
  image: string;
}

export const Section = ({headerText,title,text,buttonText,image}:SectionProps) => {

    const arrowLeft = require('../../assets/svg/CaretRight2.svg').default;

  return (
    <S.Container>
      <Icons icon={image} width={612} height={356}/>
      <S.RightContainer>
      <S.Content>
        <S.Title>{headerText}</S.Title>
        <S.Subtitle>{title}</S.Subtitle>
        <S.Text>{text}</S.Text>
      </S.Content>
        <Button iconDirection='right' disabled={false} icon={arrowLeft} title={buttonText} iconHeight={14} iconWidth={14} onClickButton={() => {}} size='large'type={'primary'} />
        </S.RightContainer>
    </S.Container>
  );
};