import { Link } from 'react-router-dom';
import { Icons } from '../../../../components/Icons';
import { Logo } from '../../../../components/Logo';
import * as S from './styles';

export const HomeFooter = () => {

const iconFacebook = require('../../../../assets/svg/facebookIcon.svg').default;
const iconInstagram = require('../../../../assets/svg/instagramIcon.svg').default;
const iconLinkedin = require('../../../../assets/svg/linkedinIcon.svg').default;

  return (
    <S.Container>
      <S.Content>
        <S.UtilLinks>
        <Logo />
        <S.CollumContainer>
          <S.Collumns>
              <p>Proprietários</p>
              <p>Incorporadoras</p>
              <p>Quem somos</p>
              <p>Blog</p>
              </S.Collumns>
          <S.Collumns>
              <p>FAQ</p>
              <p>Suporte</p>
              <p>Termos de uso e condições de cancelamento</p>
          </S.Collumns>
        </S.CollumContainer>
        </S.UtilLinks>
        <S.FooterInfos>
          <p>Yogha Gestão e Hospitalidade CNPJ: 32.303.641/0001-01 </p>
          <S.SocialMedia>
              <p>Siga Nossas Redes Sociais</p>
              <S.FooterIcons>
                    <Link target={'_blank'} to={'https://www.facebook.com/yogha.host'}>
                    <Icons icon={iconFacebook} width={24} height={24} />
                    </Link>

                    <Link target={'_blank'} to={'https://www.instagram.com/yogha.oficial/'}>
                    <Icons icon={iconInstagram} width={24} height={24} />
                    </Link>
                    <Link target={'_blank'} to={'https://www.linkedin.com/company/65265804/'}>
                    <Icons icon={iconLinkedin} width={24} height={24} />
                    </Link>
              </S.FooterIcons>
          </S.SocialMedia>
        </S.FooterInfos>
      </S.Content>
    </S.Container>
  );
};