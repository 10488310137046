import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AnyProvider } from './hooks/Context';
import { SpaProvider } from './hooks/spaRouterContext';
import { UserProvider } from './hooks/userContext';
import GlobalStyle from './styles/style';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <UserProvider>
    <AnyProvider>
    <SpaProvider>
        <GlobalStyle />
        <App />
    </SpaProvider>
        </AnyProvider>
  </UserProvider>
);
