import * as S from './styles';

interface ButtonCarouselProps {
    direction: 'left' |'right';
    onClick: () => void;
}

export const ButtonCarousel = ({direction,onClick}:ButtonCarouselProps) => {

const iconArrowLeft = require('../../assets/svg/arrowLeft.svg').default;
const iconArrowRight = require('../../assets/svg/arrowRight.svg').default;

    return (
        <S.Container onClick={onClick}>
            { direction === 'left' && <S.Icon src={iconArrowLeft} alt="Botão com seta para esquerda" />}
            { direction === 'right' && <S.Icon src={iconArrowRight} alt="Botão com seta para direita" />}
        </S.Container>
    )
};