import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { NEUTRAL_COLORS, PRIMARY_COLORS } from '../../constants/styleConstants';
import { ImageCardProps } from './types';

export const GlobalContainer = styled.div`
  display: flex;
  width: 310px;
  height: 341px;
  border-radius: 10px;
  margin-top: 16px;
  margin-left:-45px ;
  margin-right: 45px;
  word-wrap: break-word;
  `;

export const Container = styled.div`
  display: inline-flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  border-radius: 10px;
&:hover {
    h1{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
`;

export const Image = styled.img`
  width: 310px;
  height: 192px;
  margin-bottom: 16px;
`;

export const ScaleImage = styled.img`
  width: 100%;
  height: 219px;
`;


export const TextTitle = styled.h1`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-size: ${FONT_SIZE.SMALL} ;
  font-weight: ${FONT_WEIGTH.HUGE};

`;

export const TextPrice = styled.p`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-size: ${FONT_SIZE.SMALL} ;
  font-weight: ${FONT_WEIGTH.HUGE};
`;

export const Infos = styled.div`
display: flex;
flex-direction: column;
row-gap:8px;
`;

export const TextOthers = styled.text`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  gap: 4px;
`;

export const SearchDescription = styled.text`
  display: flex;
  font-weight: ${FONT_WEIGTH.MEDIUM};
  font-size: ${FONT_SIZE.LITTLE};
  `;

export const AddressText = styled.text`
color: ${NEUTRAL_COLORS.DARK_GRAY};
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_SIZE.SMALL};
`;


export const TextFrom = styled.text`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-weight: ${FONT_WEIGTH.MEDIUM};
  font-size: ${FONT_SIZE.LITTLE};
`;

export const PostContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  word-wrap: break-word !important;
  white-space: pre-wrap ;
  word-break: break-word !important;
    p{
      &:hover{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
  `

export const TextPost = styled.p`
  font-weight: ${FONT_WEIGTH.HUGE};
  font-size: ${FONT_SIZE.SMALL};
  color: ${NEUTRAL_COLORS.DARK};
`;

type SizeCard = Pick<ImageCardProps, 'sizeCard'>;

export const LinkContainer = styled.div<SizeCard>`
  display:flex;
  flex-direction:column;
  width:310px;
  height:${(props ) => props.sizeCard === 'imoveis' ? '338px' : props.sizeCard === 'posts' ? '274px' : ''};;
  justify-content: center;
  align-items: center;
  border:1px solid ${NEUTRAL_COLORS.GRAY};
  border-radius: 10px;
  row-gap:32px;
`;


export const LinkText = styled.div`
width: 220px;
line-height: 1.4;
font-weight: ${FONT_WEIGTH.HUGE};
font-size: ${FONT_SIZE.SMALL};
color: ${NEUTRAL_COLORS.HIGH_DARK};
text-align: center;
word-wrap: break-word;
white-space: pre-wrap;
  `;

export const ScaleContainer = styled.div`
  width: 302px;
  display: flex;
  border-radius: 10px;
  width: 100%;
  word-wrap: break-word;
  cursor: pointer;
  &:hover {
    h1{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
@media (min-width: 1360px) {
  width: min(100vw, 45%);
}
@media (min-width: 1728px) {
  width: min(100vw, 31%);
}
  `;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  word-wrap: break-word !important;
  white-space: pre-wrap ;
  word-break: break-word !important;
    p{
      &:hover{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
`;