// TABLE OF FONT SIZES

export const FONT_SIZE = {
  HUGE: '32px',
  LARGE: '24px',
  MEDIUM: '20px',
  SMALL: '16px',
  TINY: '14px',
  LITTLE: '12px',
} as const;

export const FONT_WEIGTH = {
  BOLD: '800',
  HUGE: '600',
  LARGE: '500',
  MEDIUM: '400',
  SMALL: '200',
  TINY: '100',
} as const