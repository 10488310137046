import styled from 'styled-components';
import { NEUTRAL_COLORS } from '../../constants/styleConstants';
import {DatePicker} from 'antd'

const { RangePicker: RangerPicker } = DatePicker;

export const Container = styled.div`
  width: 302px;
  height: 52px;
  border: 1px solid transparent;
  border-radius: 10px;
  color: ${NEUTRAL_COLORS.DARK_GRAY};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const LeftContainer = styled.div`
  width: 50%;
  height: 100%;
  border-right: 1px solid ${NEUTRAL_COLORS.GRAY};
  display: flex;
  align-items: center;
  padding: 0px 14px;
  justify-content: space-around;
`;

export const RightContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 16px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const Text = styled.text`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

export const RangerPick = styled(RangerPicker)`
  width: 302px;
  height: 52px;
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  border-radius: 10px;
  color: ${NEUTRAL_COLORS.DARK_GRAY};
  display: flex;
  align-items: center;
  white-space: nowrap;
  &::placeholder {
    color: ${NEUTRAL_COLORS.DARK};
  }
 .ant-picker{
  border: none;
 }
 z-index: 1010;
`;