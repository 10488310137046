import { createContext, useContext, useState } from 'react';

export type UserContextType = {
  user: string;
  email: string;
  token: string;
  setUser: (newState: string) => void;
  setEmail: (newState: string) => void;
  setToken: (newState: string) => void;
};

export const UserContext = createContext<UserContextType>({
  user: 'Yogha',
  email: 'teste@yogha.com.br',
  token: 'LIJBHSDAFUNASVEVFWOÇAEIVNFEVFWÇNÇNEV',
  setUser: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  setEmail: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  setToken: function (newState: string): void {
    throw new Error('Function not implemented.');
  }
});

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        email,
        setEmail,
        token,
        setToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserProvider = () => {
  const context = useContext(UserContext);
  return context;
};

export { useUserProvider, UserProvider };
