import { Container, SingleContainer } from './styles';

export const NavBar = ({selected,setSelected}:any) => {


  return (
    <Container>
      <SingleContainer selected={selected === 0} onClick={() => setSelected(0)}>Em destaque</SingleContainer>
      <SingleContainer selected={selected === 1} onClick={() => setSelected(1)}>Promoções</SingleContainer>
      <SingleContainer selected={selected === 2} onClick={() => setSelected(2)}>Cidades</SingleContainer>
      <SingleContainer selected={selected === 3} onClick={() => setSelected(3)}>Unidades</SingleContainer>
    </Container>
  );
};
