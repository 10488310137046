import { ChangeEvent, useCallback, useRef, useState } from "react"
import { Statement } from "typescript"
import { Icons } from "../Icons"
import * as S from "./styles"

export type SelectOption = {
  label: string,
  value: string | number,
}

export type SelectPropsWithFilterAndMap = {
  filter(arg0: (item: any) => boolean): SelectOption,
  map(arg0: (option: any) => JSX.Element): JSX.Element
} & SelectOption

type SelectProps = {
  disabled?: boolean
  options: SelectOption[]
  value?: SelectOption
  optionLabel?: string
  type?: 'dropdown' | 'input'
  placeholder?: string
  onChange: (value: SelectOption | undefined) => void
  widthDrop: number
  setSearch?: any
  search?: any
  hasResult?: any
  cleanInput?:boolean
}

const mapPin = require("../../assets/svg/MapPin.svg").default

export function Dropdown({ value, options, disabled = false, optionLabel, type, placeholder, onChange, widthDrop,search,setSearch,hasResult = null, cleanInput}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [selectedOption, setSelectedOption] = useState('')
  const childRef = useRef<HTMLLIElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const [validateInput, setValidateInput] = useState(search?.length > 0 ? false : true)

  const handleFocus = () => {
    if(inputRef.current) {
      inputRef.current.focus();
    }
    if (childRef.current) {
      childRef.current.focus();
    }
  };


const cleanDropdown = useCallback(()  =>  {
    setSelectedOption('')
    setIsSelected(false)
    setValidateInput(false)
    return null
},[setSelectedOption])


  const carret = require("../../assets/svg/carret.svg").default

  function isOptionSelected(option: SelectOption) {
    return option === value
  }

  function filterData(options: SelectPropsWithFilterAndMap | any, search: string): Array<SelectPropsWithFilterAndMap> {
    const total = options.filter((item: any) => {
      if(search === undefined){
         return item;
        } else {
          const values = Object.values(item).join(" ").toLowerCase();
          const result = values.includes(search?.toLowerCase());
          return result;
        }
    }
    )
    return total;
  }

  const resultLI = (hasResult:any) => {
    if(hasResult !== null)
    return (<S.ResultLi> <img src={mapPin} style={{marginTop:'-3px'}} alt="" /> Resultado</S.ResultLi>)
  }

  const filteredOptions = filterData(options, search)

  let inputDropdownList = null;

  if (filteredOptions?.length === 0) {
    inputDropdownList = <li style={{
      fontSize: 14,
    }}>Não encontramos o que busca</li>;
  } else {
    inputDropdownList =  [resultLI(hasResult),filteredOptions.map((option) => {
      return (
        <li
         ref={childRef}
          value={option.value}
          onClick={(e) => {
            setSelectedOption(e.currentTarget.innerHTML);
            setSearch(e.currentTarget.innerHTML)
            selectOption(option);
            setIsSelected(true);
            setValidateInput(true)
          }}
          key={option.value}
          className={isOptionSelected(option) ? "selected" : ""}
        >
          {option.label}
        </li>
      );
})]
  }


  const commonDropdownList = options.map((option) => (
    <li style={{paddingLeft:16}} value={option.value}
      onClick={e => {
        setSelectedOption(e.currentTarget.innerHTML)
        selectOption(option)
        setIsSelected(true)
      }}
      key={option.value}
      className={`option ${isOptionSelected(option) ? "option selected" : ""
        } `}
    >
      {option.label}
    </li>
  ))

  const selectOption = (option: SelectOption) => {
    if (option !== value) onChange(option)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(search?.length > 0){
      setIsOpen(true)
    }
    setSearch(e.target.value)
  }

  return (
    <S.DropdownContainer widthDrop={widthDrop}
      isSelected={isSelected}
      disabled={disabled}
      isOpen={isOpen}
      ref={containerRef}
      onBlur={() => {
        if(validateInput === false)
        if (!childRef.current?.contains(document.activeElement) && !containerRef.current?.contains(document.activeElement)
          && !inputRef.current?.contains(document.activeElement) && !ulRef.current?.contains(document.activeElement))

        return setIsOpen(false)
      }}
      onClick={() => {
        setIsOpen(prev => !prev)
        handleFocus()
      }}
      tabIndex={0}
    >
      {type === 'dropdown' ?
         <>
          <span>{selectedOption?.length > 0 ? selectedOption : placeholder}</span>
          <img src={carret} alt="" />
         </>
        :
        <input  ref={inputRef} value={search} onFocus={() => setIsSelected(true)} onBlur={() => {
          if(search?.length === 0)
          setIsSelected(false)
        }} placeholder={placeholder} onChange={handleChange} />
      }
      <ul ref={ulRef} className={`options ${isOpen ? "options show" : ""}`}>
        {type === 'input' ?
         inputDropdownList : commonDropdownList}
      </ul>
      { cleanInput === true && cleanDropdown()}
    </S.DropdownContainer>
  )
}
