import { MapContainer, Popup, ZoomControl } from 'react-leaflet';
import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { NEUTRAL_COLORS, PRIMARY_COLORS } from '../../constants/styleConstants';

export const CustomMapContainer = styled(MapContainer)`
position: absolute;
height: calc(100vh - 90px);
width: 100%;
margin: 5px;
margin-top: 88px;
.iconMap{
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 600;
    background: #fff;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${PRIMARY_COLORS.MEDIUM};
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
    &:focus{
        background-color: ${PRIMARY_COLORS.MEDIUM};
        color: ${NEUTRAL_COLORS.WHITE};
        border:0
    }
    &:hover{
        border: 2px solid ${PRIMARY_COLORS.MEDIUM};
    }
    &:has(div):focus{
        background-color: ${PRIMARY_COLORS.MEDIUM};
    }
    &::selection{
        background-color: purple;
    }
}
`;

export const CustomZoomControl = styled(ZoomControl)`
border-radius: 50% !important;
background: #fff !important;
 `;

export const CustomPopup = styled(Popup)`

width: 360px;
span{
    height: 24px;
    padding-top:4px;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1.4;
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    img{
        margin-top:-4px;
        width: 23px;
        height: 20px;
    }
}
p{
    margin: 8px 0px;
    line-height: 1.4;
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    color: ${NEUTRAL_COLORS.DARK_GRAY};
}
text{
    line-height: 1.4;
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
}

`;