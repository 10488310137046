import { useState,useContext,useEffect,useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { Dropdown } from '../../../../components/Dropdown';
import { ImageCard } from '../../../../components/ImageCard';
import { ImageCarousel } from '../../../../components/ImageCarousel';
import { Logo } from '../../../../components/Logo';
import { NavBar } from '../../../../components/NavBar';
import { User } from '../../../../components/User';
import { useAnyProvider} from '../../../../hooks/Context';
import { useSpaProvider } from '../../../../hooks/spaRouterContext';
import { citys, oportunitys, unitsYogha } from '../HomeBody/textPrototype';
import { GlobalContainer, HeaderContainer, HidedContainer, LeftContainer, NavContainer, RightContainer} from './styles';
import { InputDate } from '../../../../components/InputDate';

export const HomeHeader = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const searchIcon = require('../../../../assets/svg/MagnifyingGlass.svg').default;
  const [value, setValue] = useState<unknown>();
  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState(0);
  const [formattedDate, setFormattedDate] = useState<boolean>(false);
  const { searchValue,setSearchValue, anyValue } =  useAnyProvider();
  const navigate = useNavigate()

  const { visible, setExpanded, expanded } = useSpaProvider();

  const handleDatesChange = (startDate: Date | null, endDate: Date | null) => {
    console.log('Datas selecionadas:', startDate, endDate);
  };


console.log('any',isDatepickerOpen)

  const handleClickOutside = (event: MouseEvent) => {
    if (headerRef.current && !headerRef.current.contains(event.target as Node)) {
      if (popupRef.current && popupRef.current.contains(event.target as Node)) {
        if (isDatepickerOpen ) {
          return;
        }
      }
      setExpanded(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  console.log('expand',expanded)

  const invalidSearch = ():boolean => {
    if(expanded === true){
      return false
    }
    if(expanded === false){
   if(searchValue !== '' && anyValue !== 'false'){
    console.log('aqui')
    return false
  }
}
      return true
  }

  console.log('date',invalidSearch())

  const array = [
    { label: '1  Hóspede', value: '1' },
    { label: '2  Hóspedes', value: '2' },
    { label: '3  Hóspedes', value: '3' },
    { label: '4  Hóspedes', value: '4' },
    { label: '5  Hóspedes', value: '5' },
    { label: '6  Hóspedes', value: '6' },
    { label: '7  Hóspedes', value: '7' },
    { label: '8  Hóspedes', value: '8' },
    { label: '9  Hóspedes', value: '9' },
  ]

  function handleTitles():string{
    switch (selected) {
      case 0:
        return 'Conheça nossas oportunidades'
      case 1:
        return 'Oportunidades para você'
      case 2:
        return 'Cidades mais procuradas'
      case 3:
        return 'Sugestões de unidades Yogha'
      default:
        return 'Em destaque'
    }
 }

  function handleCarousel():any{
    switch (selected) {
      case 0:
        return unitsYogha
      case 1:
        return oportunitys
      case 2:
        return citys
      case 3:
        return unitsYogha
      default:
        return null
    }
 }



 const carouselTitle = handleTitles()

 const carousel = handleCarousel()

 console.log('selected',carousel)

  const cidade = [
    { label: 'São Paulo', value: 1 },
    { label: 'Rio de Janeiro', value: 2 },
    { label: 'Belo Horizonte', value: 3 },
    { label: 'Porto Alegre', value: 4 },
    { label: 'Curitiba', value: 5 },
  ]



  return (
    <>
      {visible &&
        <GlobalContainer ref={headerRef} onClick={() => setExpanded(false)} expanded={expanded}>
          <HeaderContainer  expanded={expanded}>
            <LeftContainer>
              <div style={{ marginTop: expanded ? '-4px' : '0px' }}>
                <Link to={'/'}>
                  <Logo />
                </Link>
              </div>
              <div style={{ display: 'flex', marginLeft: '48px', gap: 16 }}>
                <div style={{ marginTop: expanded ? '0px' : '0px' }}>
                  <Dropdown  widthDrop={302} type='input' hasResult={'has'} onChange={() => { }} placeholder={'Busque por cidade, unidade, bairro...'} search={searchValue} setSearch={setSearchValue} options={cidade} />
                </div>
                <HidedContainer expanded={expanded}>
                  {!expanded && <InputDate />}
                  {!expanded && <Dropdown placeholder='Hóspedes' widthDrop={174} onChange={(o) => setValue(o)} type='dropdown' options={array} />}
                </HidedContainer>
                <div style={{marginLeft: expanded ? '-14px' : '0px'}}>
                <Button title='Buscar' icon={searchIcon} iconDirection='left' iconHeight={24} iconWidth={24} size='large' type='primary' disabled={invalidSearch()} onClickButton={() => navigate('/resultados')} />
                </div>
              </div>
            </LeftContainer>
            {expanded && <RightContainer>
              <User />
            </RightContainer>}
          </HeaderContainer>
          {!expanded &&
            <NavContainer>
              <NavBar selected={selected} setSelected={setSelected} />
            </NavContainer>
          }
          {
            !expanded && <div style={{ display: 'flex', overflow: 'hidden', width: '100%' }}>
              <ImageCarousel titleCarousel={carouselTitle} images={carousel}>
                {carousel.map((image: any) => (
                  <ImageCard
                    {...image}
                  />
                ))}
              </ImageCarousel>
            </div>
          }
        </GlobalContainer>
      }
    </>
  );
};
