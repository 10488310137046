import { createGlobalStyle } from 'styled-components';
import { NEUTRAL_COLORS, PRIMARY_COLORS } from '../constants/styleConstants';

const GlobalStyle = createGlobalStyle`
    body {
        color: black;
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        overflow: hidden;
        line-height: 140%;
        letter-spacing: 0%;
    }

    * {
          /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${NEUTRAL_COLORS.GRAY};
    border-radius: 28px;
  }
        margin: 0;
        padding: 0;
        font-family: Poppins;

    }
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: ${PRIMARY_COLORS.MEDIUM} !important;
  }

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: ${PRIMARY_COLORS.MEDIUM} !important;

}

.ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: ${PRIMARY_COLORS.LIGHT} !important;

}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: ${PRIMARY_COLORS.LIGHTEST} !important;
}

.ant-btn-primary {
background-color: ${PRIMARY_COLORS.MEDIUM} !important;
border-color: ${PRIMARY_COLORS.MEDIUM} !important;
}

.ant-picker-active-bar{
  background:${PRIMARY_COLORS.MEDIUM} !important;
}

.ant-picker-focused {
     border-color: none;
    border-inline-end-width: 1px;
}

.ant-picker .ant-picker-input >input {
  padding: 12px;
  ::placeholder {
    color: ${NEUTRAL_COLORS.DARK_GRAY};

  }
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner{
  border-radius: 8px;
}

.ant-picker:hover, .ant-picker-focused{
  border-color: ${PRIMARY_COLORS.MEDIUM};
  border:1px solid ${PRIMARY_COLORS.MEDIUM};
}

.ant-picker-dropdown .ant-picker-content th{
  color: ${NEUTRAL_COLORS.DARK_GRAY} !important;
 }

.ant-picker-dropdown .ant-picker-cell {
    padding: 9px 0px;
}

.leaflet-popup-close-button{
  width: 35px;
  padding-right: 12px;
  padding-top: 10px;
    span{
      font-size: 40px;
      display: flex;
      color: ${NEUTRAL_COLORS.HIGH_DARK};
      font-weight: 200;

    }
}

.leaflet-touch .leaflet-bar{
  display: flex;
}

.leaflet-control-zoom-in,.leaflet-control-zoom-out{
 font: 400 24px 'Lucida Console', Monaco, monospace;
 padding: 0px 2px;

}

.leaflet-touch .leaflet-bar{
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  box-shadow:  1px 2px 4px rgba(0,0,0,0.25);
}

.leaflet-touch .leaflet-bar a:first-child {

  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.leaflet-bar a {
   color: ${PRIMARY_COLORS.MEDIUM};
   &:hover{
  background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};}

}

 .leaflet-touch .leaflet-control-zoom-out{
  font-size:26px
}
`
export default GlobalStyle;
