import { Icons } from '../Icons';
import * as S from './styles';

interface CommentsUsersProps {
    comment: string;
    user: string;
    reference: string;
    icon: string;
}


export const CommentsUsers = ({comment,user,reference,icon}:CommentsUsersProps) => {

    return (
        <S.Container>
            <S.Comments>
                <S.Title>O que dizem os nossos moradores</S.Title>
                <div style={{gap:24, display:'flex', flexDirection:'column'}}>
                <S.Comment>{comment}</S.Comment>
                <S.UserContainer>
                    <Icons icon={icon} height={48}  width={48}/>
                    <S.UserInfos>
                    <S.UserName>{user}</S.UserName>
                    <S.UserReference>{reference}</S.UserReference>
                    </S.UserInfos>
                </S.UserContainer>
                </div>
            </S.Comments>
        </S.Container>
    );
};