import { useState } from "react";
import { Filter } from "./components/Filter";
import { Icons } from "../../components/Icons";
import { ImageCard } from "../../components/ImageCard";
import { oportunitys } from "../Home/components/HomeBody/textPrototype";
import { HomeHeader } from "../Home/components/HomeHeader";

import * as S from "./styles";
import {Location} from "../../components/Map";
import { useAnyProvider } from "../../hooks/Context";


export const Results = () => {
  const [closed, setClosed] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const { searchValue} =  useAnyProvider();

  const arrowIcon = require('../../assets/svg/arrowNotice.svg').default;
  const arrowClosedModal = require('../../assets/svg/arrowLeft.svg').default;
  const arrowOpen = require('../../assets/svg/arrowRight.svg').default;
  const filterIcon = require('../../assets/svg/filterIcon.svg').default;

  const icon = <Icons icon={arrowClosedModal} height={24} width={17} />
  const iconclosed = <Icons icon={arrowOpen} height={24} width={17} />



  function changeKey(array: any, chave: any, novoValor: any): Array<any> {
    return array.map((obj: any) => {
      return {
        ...obj,
        [chave]: novoValor
      };
    });
  }

  const results= [
    {
      cardType:'search',
      title: 'Rua Riachuelo, 110 - AYN055',
      image: require('../../assets/images/oportunitys/oportunity1.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '158',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
      city:'Curitiba'
    },
    {
      cardType:'search',
      title: 'Rua Riachuelo, 110 - AYN045',
      image: require('../../assets/images/oportunitys/oportunity2.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '146',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
      city:'Curitiba'
    },
    {
      cardType:'search',
      title: 'Av. Silva Jardim, 2424 - HSB007',
      image: require('../../assets/images/oportunitys/oportunity3.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '199',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
      city:'Curitiba'
    },
    {
      cardType:'search',
      title: 'Rua Amintas de Barros, 240 - HUBN001',
      image: require('../../assets/images/oportunitys/oportunity4.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '154',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
      city:'Curitiba'
    },
    {
      cardType:'search',
      title: 'Rua Riachuelo, 110 - AYN055',
      image: require('../../assets/images/oportunitys/oportunity1.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '158',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
      city:'Curitiba'
    },
    {
      cardType:'search',
      title: 'Rua Riachuelo, 110 - AYN045',
      image: require('../../assets/images/oportunitys/oportunity2.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '146',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
    },
    {
      cardType:'search',
      title: 'Av. Silva Jardim, 2424 - HSB007',
      image: require('../../assets/images/oportunitys/oportunity3.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '199',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
    },
    {
      cardType:'search',
      title: 'Rua Amintas de Barros, 240 - HUBN001',
      image: require('../../assets/images/oportunitys/oportunity4.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '154',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
    },
    {
      cardType:'search',
      title: 'Av. Silva Jardim, 2424 - HSB007',
      image: require('../../assets/images/oportunitys/oportunity3.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '199',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
    },
    {
      cardType:'search',
      title: 'Rua Amintas de Barros, 240 - HUBN001',
      image: require('../../assets/images/oportunitys/oportunity4.png'),
      location: 'Centro - Curitiba, PR',
      address: '',
      price: '154',
      description:`1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
    },
  ];

  const searchArray = oportunitys.length - 1

  return (
    <div>
      <HomeHeader />
      <Location/>
      <S.ResultsContainer closed={closed}>
        <S.ResultsHeader>
          <S.ResultRow>
            <S.ContentResult>
              <S.InfoSearch>
                <S.ResultsTitle>Inicio <Icons icon={arrowIcon} width={6} height={11} />  <S.SearchResult>{searchValue}</S.SearchResult></S.ResultsTitle>
                <S.Results> {searchArray} acomodações para alugar em {searchValue} </S.Results>
              </S.InfoSearch>
            </S.ContentResult>
            <S.FilterButton onClick={() => setOpenFilter(!openFilter)}> <Icons icon={filterIcon} height={15} width={18} />Filtros</S.FilterButton>
            {openFilter && <Filter onClick={() => setOpenFilter(!openFilter)} />}
          </S.ResultRow>
        </S.ResultsHeader>
        <S.ResultsList>
            <S.ResultContent>
                {results.map((item: any, index) => (

                  <ImageCard  key={item.title} {...item} cardType="search"  />
                ))}
            </S.ResultContent>
        </S.ResultsList>
      </S.ResultsContainer>
      <S.ClosedButton closed={closed} onClick={() => setClosed(!closed)}>
        {closed ? <p style={{ display: "flex", alignItems: 'center', gap: 8 }}>Mostrar lista {iconclosed}</p> : icon}
      </S.ClosedButton>
    </div>
  );
};