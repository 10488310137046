import { Container, RangerPick } from './styles';
import ptBr from 'antd/es/date-picker/locale/pt_BR'
import moment, { Moment } from 'moment';
import { useState } from 'react';

export const InputDate = () => {

  const [date, setDate] = useState<moment.Moment | null>(moment(new Date()));

  const calendarIcon = require('../../assets/svg/CalendarBlank.svg').default;

  const separator = <p style={{fontSize:55, fontWeight:100}}>|</p>
  return (
    <Container>
      <RangerPick
      locale={ptBr}
      onChange={(dates:any) => {
        if(dates !== null)
        setDate(dates.map((date:any) => {
          return moment(new Date(date)).format('DD/MM/YYYY')}
        ))
      }}
      format="DD/MM/YYYY"

      separator={separator}
      placeholder={['Check-in', 'Check-out']}
      popupStyle={{background:'none', marginRight:'16px', zIndex:1000}}
      disabledDate={(current) => {
        return moment().add(-1, 'days')  >= current
        }}
      />
    </Container>
  );
};
