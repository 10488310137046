import { IInput } from "./types"
import * as S from "./styles"

export const Input = ({htmlFor, value, onChange, disabled = false, errorMessages, type, placeholder, title, errorText,dataList,listData,readOnly=false,heightInput=undefined} : IInput ) => {
    return (
        <>
        { type !== 'checkbox' && <S.InputContainer>
        {title !== null && <label htmlFor={htmlFor}>{title}</label>}
        { heightInput === undefined ? <S.Inputs heightInput={heightInput} list={dataList} readOnly={readOnly} id={htmlFor} disabled={disabled} type={type}  placeholder={placeholder} value={value} onChange={onChange} errorMessages={errorMessages}  /> : <S.TextArea value={value} onChange={onChange}/>}
               {errorMessages === true && <S.ErrorText errorMessages={errorMessages}>{errorText}</S.ErrorText>}
        </S.InputContainer>}
        { type === 'checkbox' &&
            <S.CheckboxContainer>
            <input id={htmlFor} disabled={disabled} type={type} value={value}
            onChange={onChange} />
            <label htmlFor={htmlFor}>{title}</label>
            </S.CheckboxContainer>
        }
        </>
    )
}