import styled from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 80px;
    `;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 1206px;
    height: 788px;
    margin: 0px 80px;
`;

export const CarouselContent = styled.div`
    display:flex;
    width: 100%;
    height: 488px;
    transform: translateX(${(props: { translate: any; }) => props.translate}%);
    transition: all 0.8s ease-in-out;
`;


export const CaroselImage = styled.div`
    width: 100%;
    margin: 0px 10%;
    @media (min-width: 1660px) {
        margin: 0px 13%;
    }
    @media (min-width: 1870px) {
        margin: 0px 17%;
    }
    @media (min-width: 2000px) {
        margin: 0px 24%;
    }
    @media (min-width: 2400px) {
        margin: 0px 29%;
    }
    &:first-child {
        margin-left: 0px;
    }
    `;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 62px;
    `;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 69px;
  background: ${NEUTRAL_COLORS.WHITE};
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  `;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
  `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    gap: 32px;
    `;