import { Filter } from '../Results/components/Filter';
import { useSpaProvider } from '../../hooks/spaRouterContext';
import { HomeBody } from './components/HomeBody';
import { HomeFooter } from './components/HomeFooter';
import { HomeHeader } from './components/HomeHeader';
import { BlurEffects, Container } from './styles';

export const Home = () => {

  const { visible, setExpanded, expanded } = useSpaProvider();

  return (
    <Container>
      <HomeHeader  />
      <BlurEffects blur={expanded} >
      <HomeBody />
      <HomeFooter/>
      </BlurEffects>
      {/* <Filter/> */}
    </Container>
  );
};
