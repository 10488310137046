import { useEffect, useState } from 'react'
import { IndexKind } from 'typescript'
import { CardSeeMore, CardSeeMoreProps } from '../CardSeeMore'
import { Icons } from '../Icons'
import * as S from './styles'
import { ButtonCarousel } from '../ButtonCarousel'

export type CardNoticeProps = {
    notices: CardSeeMoreProps[]
    imageNotice: any[]
}


export const CardNotice = ( props : CardNoticeProps) => {

const [screenSize, setScreenSize] = useState({ width: window.innerWidth });

const handleResize = () => {
    setScreenSize({ width: window.innerWidth,  });
  };

useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

const [currentSlide, setCurrentSlide] = useState(0)

const handleScreenSize = () => {
    if(screenSize.width > 2400){
        return -currentSlide * 158
    }  else if (screenSize.width > 2000){
        return -currentSlide * 148
    }else if (screenSize.width > 1860 && screenSize.width < 2000){
        return -currentSlide * 134
    }else if (screenSize.width > 1600 && screenSize.width < 2000){
        return -currentSlide * 126
    } else{
        return -currentSlide * 120
    }
}

const arrowIcon = require('../../assets/svg/arrowNotice.svg').default;

const {notices,imageNotice} = props

const prevSlider = () => {
    setCurrentSlide((currentSlide) => (currentSlide === 0 ? imageNotice.length - 1 : currentSlide - 1))
    }

const nextSlider = () => {
    setCurrentSlide((currentSlide) => (currentSlide ===  imageNotice.length - 1 ? 0 : currentSlide + 1))
    }

    return (
            <S.Container>
                <S.Content>
                    <S.CarouselContent translate={handleScreenSize}>
                {imageNotice.map((imageNotice: any) => (
                <S.CaroselImage >
                    <Icons icon={imageNotice.icon} width={'1206px'} height={488}  />
                </S.CaroselImage>
                ))}
                </S.CarouselContent>
                <S.ButtonContainer>
                    <ButtonCarousel onClick={prevSlider} direction='left' />
                    {imageNotice.map((_:any, i:number) => {
                        return (<S.Bullet active={currentSlide === i ? true : false}
                      />

)})}
                    <ButtonCarousel onClick={nextSlider} direction='right'/>
                </S.ButtonContainer>

                <S.CardContainer>
                {notices.map((notice: CardSeeMoreProps) => (
                    <CardSeeMore
                    title={notice.title}
                    description={notice.description}
                    icon={notice.icon}
                    />
                ))}
                </S.CardContainer>
                </S.Content>
        </S.Container>
        )
    }