import styled from 'styled-components';
import { NEUTRAL_COLORS } from '../../constants/styleConstants';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80px;
  height: 56px;
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  border-radius: 10px;
  padding: 0px 16px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
`;

export const PhotoGrid = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 99px;
`;

export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 99px;
`;